var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column",
      staticStyle: { "padding-top": "108px !important" },
    },
    [
      _c("v-breadcrumbs", {
        staticStyle: {
          "padding-left": "5px !important",
          "max-height": "36px !important",
          "flex-direction": "row",
          display: "flex",
          padding: "0px !important",
          margin: "0px !important",
        },
        attrs: { items: _vm.items },
        scopedSlots: _vm._u([
          {
            key: "divider",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "material-icons-outlined",
                    staticStyle: {
                      color: "#8d8d99 !important",
                      "font-weight": "400 !important",
                      "pointer-events": "none !important",
                    },
                    attrs: { id: "arrowId" },
                  },
                  [_vm._v(" keyboard_arrow_right ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                item.title === "Home"
                  ? _c(
                      "v-breadcrumbs-item",
                      {
                        attrs: {
                          icon: "",
                          href: item.href,
                          disabled: item.disabled,
                          exact: "",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "shadow-none",
                            staticStyle: { width: "32px", height: "32px" },
                            attrs: {
                              fab: "",
                              "x-large": "",
                              id: "homeButton",
                              ripple: false,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "material-icons-outlined shadow-none iconColor--text",
                              },
                              [_vm._v(" home ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-breadcrumbs-item",
                      { attrs: { href: item.href, disabled: item.disabled } },
                      [
                        _c("span", { staticClass: "wssbreadTittle" }, [
                          _vm._v(" " + _vm._s(item.title)),
                        ]),
                      ]
                    ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-row",
        { staticClass: "my-0 pt-8 pb-8" },
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "8" } }, [
            _c(
              "h2",
              {
                staticClass: "titleColor--text",
                style: "font-size: 32px!important; font-weight: bold!important",
              },
              [_vm._v(" Agentes nocivos e aposentadoria especial ")]
            ),
            _c("p", { attrs: { id: "subtitle" } }, [
              _vm._v(
                "Monitoramento de risco de exposição profissional e aposentadoria especial"
              ),
            ]),
          ]),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-end justify-end py-0",
              attrs: { cols: "4" },
            },
            [
              _vm.isDisabled
                ? _c(
                    "p",
                    { staticClass: "subtitle-link" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            color: "#2d5796 !important",
                            "background-color": "transparent !important",
                            "font-weight": "400 !important",
                            padding: "0px !important",
                          },
                          attrs: { text: "", ripple: false },
                          on: {
                            click: function ($event) {
                              return _vm.abrirModal(true)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "material-icons-outlined mr-2",
                              attrs: { id: "iconRestart" },
                            },
                            [_vm._v("restart_alt")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "textChange",
                              staticStyle: {
                                color: "#2d5796",
                                "font-size": "12px !important",
                              },
                            },
                            [_vm._v(" Atualização realizada ")]
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#52526b",
                            "padding-left": "4px",
                            "font-size": "12px !important",
                          },
                        },
                        [_vm._v(" em " + _vm._s(_vm.lastHistory) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("HistoryModal"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }