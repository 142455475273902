var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        {
          class: _vm.active
            ? "cardMainForFilterActive"
            : !_vm.boolForDisabled
            ? "cardMainForFilter"
            : "cardMainForFilterDisabled",
          style:
            _vm.companiesList.length <= 0
              ? "pointer-events: none !important;"
              : _vm.boolForTags
              ? "pointer-events: none !important;"
              : "",
          attrs: { id: "divCardInside", disabled: _vm.boolForDisabled },
          on: {
            click: function ($event) {
              !_vm.boolForTags ? _vm.callMethod() : ""
            },
          },
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "titlecard titleColor--text",
              attrs: { id: "titleGraphFilter" },
            },
            [_vm._v(" " + _vm._s(_vm.graphInformation.title) + " ")]
          ),
          _c(
            "div",
            {
              staticClass: "cardforgraphandText d-flex",
              attrs: { id: "cardForTextandGraph" },
            },
            [
              _c("div", { staticClass: "cardForText flex-column mr-8 pa-0" }, [
                _c(
                  "div",
                  { staticClass: "qtdColab mr-8", attrs: { id: "qtdColab" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.graphInformation.qtdColab[0] > 0
                            ? _vm.graphInformation.qtdColab[0]
                            : 0
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "percentColab text-no-wrap",
                    attrs: { id: "percentColab" },
                  },
                  [_vm._v(" " + _vm._s(_vm.percentualOfColab) + " ")]
                ),
              ]),
              _vm.graphInformation.qtdColab[0] > 0
                ? _c("DonutForFilter", {
                    attrs: {
                      serie: _vm.graphInformation.qtdColab,
                      active: _vm.active,
                      disabled: _vm.boolForTags,
                    },
                  })
                : _c("NoDataDonut"),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "cardMainLoading",
          attrs: { id: "divCardInside" },
          on: {
            click: function ($event) {
              return _vm.callMethod()
            },
          },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "titlecard", attrs: { id: "titleGraphFilter" } },
            [_vm._v(" " + _vm._s(_vm.graphInformation.title))]
          ),
          _c(
            "div",
            {
              staticClass: "cardforgraphandText d-flex",
              attrs: { id: "cardForTextandGraph" },
            },
            [
              _vm._m(0),
              _vm.loading
                ? _c("v-progress-circular", {
                    staticClass: "mt-2",
                    attrs: {
                      size: 75,
                      width: 16,
                      color: "#FFFFFF",
                      id: "circularProgress",
                      indeterminate: "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardForText flex-column mr-8 pa-0" }, [
      _c("div", { staticClass: "qtdColab mr-8", attrs: { id: "qtdColab" } }, [
        _vm._v("0"),
      ]),
      _c(
        "div",
        {
          staticClass: "percentColab text-no-wrap",
          attrs: { id: "percentColab" },
        },
        [_vm._v("0% do total")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }