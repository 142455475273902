<template>
  <v-main style="background-color: #f7f7fd">
    <HeaderAgentesNocivosEAposentariaEspecial ref="headerAgentesNocivosEAposentariaEspecial" />
    <v-card class="d-flex flex-column" id="cardDash" elevation="0">
      <FiltroPrimario />
      <div class="d-flex justify-space-between mb-4">
        <GraficoAposentadoriaEspecial />

        <GraficoAgentesNocivos />
      </div>
      <div class="d-flex justify-space-around">
        <v-col style="padding: 0px !important">
          <TabsAgentesNocivosEAposentariaEspecial />
        </v-col>
      </div>
    </v-card>
  </v-main>
</template>

<script>
import FiltroPrimario from './components/PrimaryFilter.vue';
import GraficoAposentadoriaEspecial from './components/SpecialRetirementGraph.vue';
import GraficoAgentesNocivos from './components/AgNocivegraph.vue';
import HeaderAgentesNocivosEAposentariaEspecial from './components/HeaderAgentesNocivosEAposentariaEspecial.vue';
import TabsAgentesNocivosEAposentariaEspecial from './components/TabsAgentesNocivosEAposentariaEspecial.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AgentesNocivosEAposentariaEspecial',
  components: {
    FiltroPrimario, 
    GraficoAposentadoriaEspecial, 
    GraficoAgentesNocivos, 
    HeaderAgentesNocivosEAposentariaEspecial, 
    TabsAgentesNocivosEAposentariaEspecial
  },
  data() {
    return {
      companies: [],
      countNociveAgentsFilter: {
        cnpjs: [],
        competencyIn: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      perfis: 'user/userAuthorities',
      userInfo: 'user/userInfo',
    }),
  },

  created() {
    this.init(this.userInfo.id);
  },
  methods: {
    ...mapActions('wss', ['init']),
    ...mapActions('user', ['getUser']),
  },
};
</script>
<style scoped>
#subtitle {
  padding-top: 8px !important;
  font-size: 16px !important;
  color: #575767 !important;
  font-weight: regular !important;
  text-align: left !important;
}
#cardDash {
  padding: 16px 16px 16px 16px;
  border: 1px solid #e7e7fa !important;
  border-radius: 4px !important;
  background-color: #ffffff;
}
#cardGraphFilter {
  padding: 16px 16px 1px;
}
</style>
<style>
.v-application.v-application--is-ltr.theme--light {
  background-color: #f7f7fd !important;
  color: var(--v-info-base) !important;
}
</style>
