var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pl-4 pt-4 pr-4 pb-4", attrs: { id: "cardBars" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "titleColor--text",
          staticStyle: {
            width: "412px",
            "padding-top": "0px",
            "padding-left": "0px",
          },
          attrs: { id: "cardTitle" },
        },
        [_vm._v(_vm._s(_vm.titleForCard) + " ")]
      ),
      _c(
        "div",
        { staticClass: "wrapper d-flex justify-center" },
        [
          _vm.serieLength >= 1 && !_vm.loading
            ? _c("apexCharts", {
                staticClass: "my-n6",
                attrs: {
                  id: "graphAgNocive",
                  type: "donut",
                  width: "324",
                  options: _vm.chartOptions,
                  series: _vm.series,
                },
              })
            : _vm._e(),
          _vm.loading
            ? _c("LoadingCircular", { staticClass: "my-8" })
            : _vm._e(),
          !_vm.loading && _vm.serieLength === 0
            ? _c("NoData", { attrs: { type: "donut", width: "324" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }