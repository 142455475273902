<template>
  <div class="d-flex flex-column" style="padding-top: 108px !important">
    <v-breadcrumbs
      :items="items"
      style="
        padding-left: 5px !important;
        max-height: 36px !important;
        flex-direction: row;
        display: flex;
        padding: 0px !important;
        margin: 0px !important;
      "
    >
      <template v-slot:divider>
        <span
          class="material-icons-outlined"
          id="arrowId"
          style="color: #8d8d99 !important; font-weight: 400 !important; pointer-events: none !important"
        >
          keyboard_arrow_right
        </span>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item icon v-if="item.title === 'Home'" :href="item.href" :disabled="item.disabled" exact>
          <v-btn fab x-large style="width: 32px; height: 32px" id="homeButton" class="shadow-none" :ripple="false">
            <span class="material-icons-outlined shadow-none iconColor--text"> home </span>
          </v-btn>
        </v-breadcrumbs-item>
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" v-else>
          <span class="wssbreadTittle"> {{ item.title }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row class="my-0 pt-8 pb-8">
      <v-col cols="8" class="py-0">
        <h2 class="titleColor--text" :style="'font-size: 32px!important; font-weight: bold!important'">
        Agentes nocivos e aposentadoria especial
        </h2>
        <p id="subtitle">Monitoramento de risco de exposição profissional e aposentadoria especial</p></v-col
      >
      <v-col cols="4" class="d-flex align-end justify-end py-0">
        <p class="subtitle-link" v-if="isDisabled">
          <!-- <v-btn style="color: #2d5796; background-color: transparent" @click="historic()"> -->
          <v-btn
            style="
              color: #2d5796 !important;
              background-color: transparent !important;
              font-weight: 400 !important;
              padding: 0px !important;
            "
            text
            :ripple="false"
            @click="abrirModal(true)"
          >
            <span class="material-icons-outlined mr-2" id="iconRestart">restart_alt</span>
            <span class="textChange" style="color: #2d5796; font-size: 12px !important"> Atualização realizada </span>
          </v-btn>
          <span style="color: #52526b; padding-left: 4px; font-size: 12px !important"> em {{ lastHistory }} </span>
        </p></v-col
      ><HistoryModal
    /></v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'HeaderAgentesNocivosEAposentariaEspecial',
  components: {
    HistoryModal: () => import('./HistoryModal.vue'),
  },

  data: () => ({
    ultimaDataAtualizacao: '08/11/2022 às 9h:30',
    items: [
      {
        title: 'Home',
        disabled: false,
        href: '/home',
      },
      {
        title: 'War Smart Solutions',
        disabled: true,
      },
      {
        title: 'Agentes nocivos e aposentadoria especial',
        disabled: true,
        href: 'agentes-nocivos-e-aposentadoria-especial',
      }
    ],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      companiesList: 'wss/companiesList',
      loading: 'wss/getLoading',
      lastHistory: 'wss/getLastHistory',
      loadingHistory: 'wss/getLoadingHistory',
    }),
    isDisabled() {
      if (this.companiesList.length > 0) {
        if (this.lastHistory != '') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions('wss', ['init', 'toggleHistoryModal']),
    ...mapActions('user', ['getUser']),
    abrirModal(bool) {
      this.toggleHistoryModal(bool);
    },
  },
};
</script>
<style scoped>
.material-icons-outlined:not('arrowId') {
  width: 16px;
  height: 16px;
  color: #2d5796 !important;
  font-weight: 400 !important;
}
.material-icons-outlined {
  font-size: 16px;
  font-weight: 400 !important;
}
.material-icons-outlined:hover:not(#iconRestart) {
  color: #2d5796 !important;
  font-weight: 400 !important;
  background-color: #e3ffee;
}
.material-icons-outlined:active {
  color: var(--v-primary-base);
  font-weight: 400 !important;
  background-color: #00ff99;
}
::v-deep .v-breadcrumbs__divider {
  width: 24px !important;
  max-width: 24px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.colBreadcrumb {
  align-items: flex-start;
  flex-direction: row;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
  max-height: 36px !important;
}
::v-deep #subtitle {
  padding-top: 8px !important;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: regular !important;
  text-align: left !important;
  margin-bottom: 0px !important;
}

::v-deep .wssbreadTittle {
  padding: 8px 16px !important;
  color: #8d8d99 !important;
  font-weight: 700 !important;
}
::v-deep .subtitle-link {
  color: #575767;
  font-weight: 400;
  margin-bottom: 0px;
}
::v-deep .subtitle-link a {
  text-decoration: none;
}

::v-deep .v-btn--text:before {
  background-color: transparent !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1;
  color: #2d5796;
}
::v-deep .v-btn--text:after {
  background-color: transparent !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1;
  color: #2d5796;
}

::v-deep .v-btn__content:hover .textChange {
  color: #3c73c7 !important;
  text-decoration: none !important;
  background-color: none !important;
}
::v-deep .v-btn__content:hover .material-icons-outlined {
  color: #3c73c7 !important;
}
::v-deep #homeButton {
  border-radius: 28px !important;
  background-color: transparent;
  box-shadow: none !important;
}
::v-deep #homeButton:hover .material-icons-outlined {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
::v-deep #homeButton:hover {
  border-radius: 28px !important;
  background-color: #e3ffee;
  box-shadow: none !important;
  outline: none !important;
}
</style>
