<template>
  <v-main style="padding: 0px 0px 0px; border: 0px solid #e7e7fa !important">
    <v-card style="border: 0px !important; border-radius: 4px !important">
      <v-card-title style="background-color: #f7f7fd; border: 1px solid #e7e7fa !important">
        <v-tabs v-model="tab">
          <v-tabs-slider :color="isDisabled() ? '#F7F7FD' : '#00FF99'"></v-tabs-slider>
          <v-tab href="#colaboradores" value="colaboradores" id="colaboradores" :disabled="isDisabled()"
            ><div class="tab-text">{{ funcTitle }} ({{ collaboratorsSize }})</div></v-tab
          >
          <v-tab href="#agentes-nocivos" value="agentes-nocivos" id="agentes-nocivos" :disabled="isDisabled()"
            ><div class="tab-text">Agentes Nocivos ({{ nociveAgentsSize }})</div></v-tab
          >
          <v-tab href="#alertas" value="alertas" id="alertas" :disabled="isDisabled()"
            ><div class="tab-text">Alertas ({{ alertsSize }})</div></v-tab
          >
        </v-tabs>
      </v-card-title>
      <v-tabs-items v-model="tab" style="background-color: #f7f7fd !important; border: 0px solid #e7e7fa !important">
        <v-tab-item :value="'colaboradores'">
          <TableColaboradores />
        </v-tab-item>
        <v-tab-item :value="'agentes-nocivos'">
          <TableAgentesNocivos />
        </v-tab-item>
        <v-tab-item :value="'alertas'">
          <TableAlertas />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-main>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import TableColaboradores from './TableColaboradores.vue';
import TableAgentesNocivos from './TableAgentesNocivos.vue';
import TableAlertas from './TableAlertas.vue';

export default {
  name: 'TabsAgentesNocivosEAposentariaEspecial',

  components: { TableColaboradores, TableAgentesNocivos, TableAlertas },

  data() {
    return {
      tab: 'colaboradores',
      funcTitle: process.env.VUE_APP_TAGFUNC,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      companiesList: 'wss/companiesList',
      selectedCompetency: 'wss/getCompetencyInSelected',
      collaboratorsSize: 'wss/getTotalCollaborators',
      alertsSize: 'wss/getTotalAlerts',
      nociveAgentsSize: 'wss/getTotalNociveAgents',
    }),
  },

  watch: {
    tab: 'getParams',
  },

  created() {},

  methods: {
    ...mapActions('wss', ['resetPagination']),
    isDisabled() {
      return this.collaboratorsSize < 1 || (this.selectedCompetency && this.selectedCompetency.length < 1);
    },
    getParams() {
      switch (this.tab) {
        case 'colaboradores':
          break;
        case 'agentes-nocivos':
          break;
        case 'alertas':
          break;
        default:
          break;
      }
    },
  },
  changeTab() {
    this.getParams();
  },

  watch: {
    tab: 'getParams',
  },

  created() {},
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}
::v-deep .v-data-table-header tr th span {
  cursor: pointer !important;
}
.v-card__title {
  padding: 0px !important;
}
.v-tabs-slider-wrapper {
  background-color: #e513b8 !important;
  color: #e513b8 !important;
  border: 2px solid #006144 !important;
}
::v-deep .v-tabs-bar {
  min-height: 50px !important;
}
.v-tab {
  padding: 8px !important;
  text-transform: none;
}

.v-tab:hover {
  background-color: rgb(253, 253, 255) !important;
}
.tab-text:hover {
  background-color: #e3ffee !important;
}
.v-tab--active {
  padding: 8px !important;
  text-transform: none;
}
.v-tab .tab-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 8px 32px;
  margin: 0px;
  border-radius: 4px;
  max-height: 32px !important;
}
.v-tab--active .tab-text {
  background-color: #e3ffee !important;
  padding: 8px 32px;
  margin: 0px;
  max-height: 32px !important;
}
::v-deep .v-slide-group__content.v-tabs-bar__content {
  background: #f7f7fd;
}
.v-tab.v-tab--disabled .tab-text {
  color: #8686a2 !important;
  background-color: #e7e7fa !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.01em !important;
  text-align: left !important;
}
.v-tab.v-tab--disabled .tab-text {
  color: #8686a2 !important;
  background-color: #e7e7fa !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.01em !important;
  text-align: left !important;
}
</style>
