<template>
  <div
    :class="active ? 'cardMainForFilterActive' : !boolForDisabled ? 'cardMainForFilter' : 'cardMainForFilterDisabled'"
    id="divCardInside"
    :disabled="boolForDisabled"
    @click="!boolForTags ? callMethod() : ''"
    v-if="!loading"
    :style="
      companiesList.length <= 0
        ? 'pointer-events: none !important;'
        : boolForTags
        ? 'pointer-events: none !important;'
        : ''
    "
  >
    <v-card-title id="titleGraphFilter" class="titlecard titleColor--text"> {{ graphInformation.title }} </v-card-title>
    <div class="cardforgraphandText d-flex" id="cardForTextandGraph">
      <div class="cardForText flex-column mr-8 pa-0">
        <div class="qtdColab mr-8" id="qtdColab">
          {{ graphInformation.qtdColab[0] > 0 ? graphInformation.qtdColab[0] : 0 }}
        </div>
        <div class="percentColab text-no-wrap" id="percentColab">
          {{ percentualOfColab }}
        </div>
      </div>

      <DonutForFilter
        :serie="graphInformation.qtdColab"
        :active="active"
        v-if="graphInformation.qtdColab[0] > 0"
        :disabled="boolForTags"
      />

      <NoDataDonut v-else />
    </div>
  </div>
  <div class="cardMainLoading" id="divCardInside" @click="callMethod()" v-else>
    <v-card-title id="titleGraphFilter" class="titlecard"> {{ graphInformation.title }}</v-card-title>
    <div class="cardforgraphandText d-flex" id="cardForTextandGraph">
      <div class="cardForText flex-column mr-8 pa-0">
        <div class="qtdColab mr-8" id="qtdColab">0</div>
        <div class="percentColab text-no-wrap" id="percentColab">0% do total</div>
      </div>
      <v-progress-circular
        v-if="loading"
        class="mt-2"
        :size="75"
        :width="16"
        color="#FFFFFF"
        id="circularProgress"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import ThemeMixin from '@/mixins/Theme.vue';
import 'dayjs/locale/pt-br';
import { mapGetters, mapActions } from 'vuex';
let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--v-primary-base');
export default {
  name: 'graphFilter',
  props: ['graphInformation', 'active', 'loading'],
  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('../../indicadores/components/NoData.vue'),
    DonutForFilter: () => import('./DonutForFilter.vue'),
    NoDataDonut: () => import('./NoDataDonutFilter.vue'),
    LoadingCircular: () => import('./LoadingCircular.vue'),
  },

  mixins: [ThemeMixin],

  data() {
    return {
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        selection: {
          enabled: false,
          type: 'x',
        },
        states: {
          active: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.98868,
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          colors: ['#E7E7FA'],
        },
        noData: {
          text: undefined,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#ECE7F5',
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        legend: {
          show: false,
          position: 'top',
          showForSingleSeries: false,
          showForZeroSeries: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
          },
          labels: {
            show: false,
            colors: '#575767',
          },
        },
        colors: [primaryColor, '#E7E7FA'],
      },
    };
  },
  computed: {
    percentualOfColab() {
      let percent = this.graphInformation.percent;
      return `${!isNaN(percent) ? percent : 0}% do total`;
    },
    boolForDisabled() {
      if (this.boolForTags) {
        return true;
      }
      if (this.companiesList.length < 1) {
        return true;
      }
      if (this.graphInformation.qtdColab[0] === 0) {
        return true;
      }
      if (!this.competency.competencyIn) {
        return true;
      }
    },

    ...mapGetters({
      isFromFilter: 'wss/getIsFromFilter',
      companiesList: 'wss/companiesList',
      boolForTags: 'wss/getBoolForTags',
      competency: 'wss/getCountNociveAgentFilter',
      boolForFilterButton: 'wss/getBoolForFilterButton',
    }),
  },
  methods: {
    ...mapActions('wss', ['setIsFromFilter']),

    callMethod() {
      this.$emit('filterNow');
    },
  },
};
</script>
<style scoped>
::v-deep .titlecard {
  font-size: 20px !important;
  font-weight: 400 !important;
  padding: 0 0 16px !important;
  color: var(--v-primary-base);
}
.cardMainForFilter {
  border: 1px solid #e7e7fa;
  background-color: #f7f7fd !important;
  width: 585px !important;
  height: 158px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}
.cardMainLoading {
  border: 1px solid #e7e7fa;
  background-color: #e7e7fa;
  width: 585px !important;
  height: 158px !important;
}
.cardMainForFilterDisabled {
  border: 1px solid #e7e7fa;
  background-color: #e7e7fa;
  width: 585px !important;
  height: 158px !important;
  pointer-events: none !important;
  cursor: pointer !important;
}
.cardMainForFilterActive {
  background-color: white;
  width: 585px !important;
  height: 158px !important;
  border-top: 4px solid var(--v-primary-base);
  border-left: 1px solid #e7e7fa;
  border-right: 1px solid #e7e7fa;
  border-bottom: 1px solid #e7e7fa;
  cursor: pointer !important;
}
.cardMainForFilter:hover {
  background-color: #e3ffee !important;
  border: 1px solid #e7e7fa !important;
}
.cardMainForFilter:disabled {
  background-color: #e7e7fa;
  border: 1px solid #e7e7fa;
  width: 585px !important;
  height: 158px !important;
}
.cardMainForFilter:active {
  background-color: white;
  border-top: 4px solid var(--v-primary-base);
  border-left: 1px solid #e7e7fa;
  border-right: 1px solid #e7e7fa;
  border-bottom: 1px solid #e7e7fa;
}
.cardforgraphandText {
  height: 80px !important;
  width: 215px !important;
  padding: 0px !important;
  margin-right: -32px !important;
}
.cardForText {
  height: 80px !important;
  width: 103px !important;
  padding: 0px;
}

.qtdColab {
  font-size: 40px;
  color: #52526b;
  font-weight: 'regular';
  height: 40px !important;
  width: 103px !important;
  margin-bottom: 16px;
}
.percentColab {
  font-size: 16px;
  color: #52526b;
  font-weight: 'regular';
  height: 24px !important;
}
</style>
