<template>
  <v-card id="cardBars" class="pl-4 pt-4 pr-4 pb-4">
    <v-card-title id="cardTitle" class="titleColor--text" style="width: 412px; padding-top: 0px; padding-left: 0px"
      >{{ titleForCard }}
    </v-card-title>

    <div class="wrapper d-flex justify-center">
      <apexCharts
        id="graphAgNocive"
        class="my-n6"
        type="donut"
        width="324"
        :options="chartOptions"
        :series="series"
        v-if="serieLength >= 1 && !loading"
      ></apexCharts>

      <LoadingCircular v-if="loading" class="my-8" />
      <NoData type="donut" width="324" v-if="!loading && serieLength === 0" />
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ThemeMixin from '@/mixins/Theme.vue';
import 'dayjs/locale/pt-br';

import debounce from 'lodash/debounce';

export default {
  name: 'GraficoAgentesNocivos',
  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('./NoDataDonut.vue'),
    LoadingCircular: () => import('./LoadingCircular.vue'),
  },
  mixins: [ThemeMixin],
  props: ['prefilter'],
  data() {
    return {
      titleForCard: 'Agentes Nocivos',
      //fetchDebounce: debounce(() => this.countNociveAgents(true), 480),

      noData: false,

      // series: [55, 5, 5, 10, 20, 5],
      chartOptions: {
        chart: {
          height: '300px',
          type: 'donut',
        },
        selection: {
          enabled: false,
          type: 'x',
        },
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        dataLabels: {
          enabled: false,
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },
        noData: {
          text: undefined,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#F7F7FD',
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        tooltip: {
          fillSeriesColor: false,
          followCursor: false,
          enable: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Roboto, sans-serif',
            backgroundColor: '#52526B',
            paddingLeft: '8px !important',
            paddingRight: '8px !important',
          },

          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0;
            for (let x of series) {
              total += x;
            }

            let selected = series[seriesIndex];
            let html =
              ' <div style="margin-top: 4px; margin-bottom: 4px; "><div style=" text-indent: 5 px;  margin-right: 8px;border-radius: 8px; width: 10px;  height: 10px; background-color: ' +
              w.config.colors[seriesIndex] +
              '; display: inline-block;">' +
              '</div>' +
              '<div style="display: inline-block; border-radius: 8px">' +
              w.config.labels[seriesIndex] +
              ' (' +
              ((selected / total) * 100).toFixed(2) +
              '%)' +
              '</div></div>';

            return html;
          },
        },

        plotOptions: {
          pie: { offsetY: 65, customScale: 0.9 },
        },

        labels: [
          'Ausentes',
          'Químicos ',
          'Físicos ',
          'Biológicos ',
          'Outros',
          'Associação de agentes nocivos físicos, químicos e biológicos',
        ],
        legend: {
          position: 'bottom',

          formatter: function (seriesName, opts) {
            return [seriesName, ' (', opts.w.globals.series[opts.seriesIndex], ')'];
          },
          horizontalAlign: 'start',

          showForSingleSeries: false,
          showForZeroSeries: true,
        },
        colors: ['#E7E7FA', '#3CC79D', '#E5D545', '#3C73C7', '#C73C44', '#9A3CC7'],
      },
    };
  },
  watch: {
    // options: {
    //   handler() {
    //     this.countNociveAgents();
    //   },
    // },
  },
  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      perfis: 'user/userAuthorities',
      loading: 'wss/getAllDataLoaded',
      series: 'wss/getCountNociveAgents',
    }),
    serieLength() {
      let notNull = 0;
      this.series.forEach((element) => {
        if (element != 0) {
          notNull++;
        }
      });
      return notNull;
    },
  },
  mounted() {
    //    this.init();
  },
  methods: {
    ...mapActions('wss', [
      'getCompanies',
      'changeApplyBtnStatus',
      'changeCompany',
      'cleanFilters',
      'changeCompetencia',

      'setLoading',
    ]),
  },
};
</script>
<style scoped>
#cardBars {
  height: 380px !important;
  width: 588px !important;
  border: 1px solid #e7e7fa !important;
  background: #f7f7fd !important;
  border-radius: 4px !important;
}
.wrapper {
  min-height: 380px !important;
  height: 380px !important;
  width: 588px !important;
  color: var(--v-primary-base);
}
</style>
