<template>
  <v-card style="border: 1px solid #e7e7fa !important">
    <div id="alertas-container">
      <v-card-title class="pt-0 pl-0 pr-0">
        <TableSearch
          label="Pesquisar por descrição"
          id="searchBarAlerts"
          v-model="filterCompetency.alertDesc"
          :disabled="tableSearchDisabled"
          @input="onInputTableAlertasSearch"
        >
        </TableSearch>

        <v-spacer />
        <ExportButton :is-disabled="isDisabled" @exportar="exportar" id="btnExport" />
      </v-card-title>
      <div id="scrollDuplicate">
        <div id="dummy" />
      </div>
      <v-data-table
        id="tablea"
        class="pb-4"
        :loading="loadingTable"
        :search="search"
        :headers="headers"
        :options.sync="pagination"
        :header-props="{ sortIcon: null }"
        :items="alertas"
        :page="pagination.page"
        :server-items-length="totalAlerts"
        :footer-props="{
          disablePagination: companiesList.length > 1 ? false : true,
          itemsPerPageOptions:
            totalAlerts < 50
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100', disabled: true },
                  { value: 250, text: '250', disabled: true },
                ]
              : totalAlerts < 100
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250', disabled: true },
                ]
              : [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250' },
                ],
          disableItemsPerPage: alertas.length === 0,
          itemsPerPageText: 'Alertas por página: ',
          expandIcon: 'mdi-dots-horizontal',
        }"
        :no-data-text="
          companiesList.length === 0
            ? 'Não há alertas a serem exibidos.'
            : 'Verifique se há alertas a serem exibidos. Selecione uma competência e aplique o filtro.'
        "
      >
        <template slot="body" v-if="loadingTable">
          <tr>
            <td v-for="loadItem in headers">
              <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
            </td>
          </tr>
        </template>
        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} alertas
        </template>
        <template v-slot:item.socialName="{ item }">
          {{ item.socialName }}
        </template>
        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj | cnpj }}
        </template>
        <template v-slot:item.desc="{ item }">
          {{ item.desc }}
        </template>

        <template v-slot:item.lastUpdate="{ item }">
          {{ item.lastUpdate | dateAsTime }}
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ExportButton from './ExportButton.vue';
import moment from 'moment';
import { eventBus } from '@/main.js';
export default {
  name: 'TableAlertas',

  components: { 
    ExportButton,
    TableSearch: () => import('../../commons/TableSearch.vue'),
  },
  updated() {
    this.customize();
    this.changeIcons();
  },
  mounted() {
    this.changeIcon2();
    this.updateScrollers();
    window.addEventListener('resize', this.updateScrollers);

    const linkScroller = (a, b) => {
      a.addEventListener('scroll', (e) => {
        b.scrollLeft = e.target.scrollLeft;
      });
    };
    const _a = this.$el.querySelector('#scrollDuplicate');
    const _b = this.$el.querySelector('.v-data-table__wrapper');
    linkScroller(_a, _b);
    linkScroller(_b, _a);
  },
  watch: {    
    alertas(value) {
      if (value.length === 0) {
        this.search = '';
        this.changeColors(true);
      } else{
        this.changeColors(false);
      }
    },   
  },
  data() {
    return {
      search: '',
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
      headers: [
        { text: 'Empresa', value: 'social_name', width: '595px' },
        { text: 'CNPJ', value: 'cnpj', width: '311px' },
        { text: 'Descrição', value: 'desc', width: '861px' },

        { text: 'Atualização', value: 'lastUpdate', width: '352px' },
      ],
      customStyleSkeleton:
        'text-align: start;color: #e7e7fa;font-family: "Roboto";font-weight: 700;font-size: 28px;letter-spacing: 1%;height: 51px;flex-direction: row;align-items: flex-start; border-left: 2px solid #ffffff',
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      companiesList: 'wss/companiesList',
      alertas: 'wss/getAlerts',
      loading: 'wss/getLoading',
      loadingTable: 'wss/getLoadingTableAlerts',
      paginationFilter: 'wss/getPaginationRiskAlert',
      totalAlerts: 'wss/getTotalAlerts',
      filterCompetency: 'wss/getCountNociveAgentFilter',
      alertBlob: 'wss/getAlertBlob',
      scheduleReport: 'wss/getScheduleReport',
    }),
    isDisabled() {
      if (this.alertas.length < 1) {
        return true;
      } else if (this.companiesList.length < 1) {
        return true;
      } else if (this.filterCompetency.competencyIn === '') {
        return true;
      } else if (this.loading) {
        return true;
      } else {
        return false;
      }
    },
    pagination: {      
      get: function () {
        return this.paginationFilter;
      },
      set: function (value) {
        this.setPaginationRiskAlert(value);
      },
    },
    tableSearchDisabled() {
        return this.loading;
    }
  },
  methods: {    
    ...mapActions('wss', ['getCompanies', 'riskAlerts', 'setPaginationRiskAlert', 'exportAlerts', 'searchDescription']),
    updateScrollers() {
      const dummy = this.$el.querySelector('#dummy');
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      dummy.style.width = _b.scrollWidth + 'px';
    },
    onInputTableAlertasSearch(val) {
      this.searchDescription(val);
    },
    customize() {
      let tableLines = document.querySelectorAll('#alertas-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },
    
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },

    changeIcon2() {
      const listItem = document.getElementsByClassName('material-icons-outlined');

      let selected = '';

      if (listItem[4] != undefined && listItem[4].innerHTML === 'expand_more') {
        //<!-- const itemSelected = listItem[4]; -->
        selected = listItem[4];
        selected.style.color = 'primary';
        selected.style.fontSize = '24px';
        selected.style.fontWeight = 'regular';
      } else {
        const listItemNew = document.getElementsByClassName('v-input__icon v-input__icon--append')[1];
        const newITem = document.createElement('span');
        newITem.setAttribute('class', 'material-icons-outlined');
        newITem.innerText = 'expand_more';

        newITem.style.color = '#8686A2';
        newITem.style.fontSize = '24px';
        newITem.style.fontWeight = 'regular';
        listItemNew.parentNode.replaceChild(newITem, listItemNew);
      }
    },

    changeIcons() {
      const listItem = document.getElementsByClassName(
        'v-icon notranslate v-icon--disabled mdi mdi-menu-down theme--light',
      );

      const itemSelected = listItem[0];

      const newITem = document.createElement('span');
      newITem.setAttribute('class', 'material-icons-outlined');
      newITem.innerText = 'expand_more';

      newITem.style.color = '#8686A2';
      newITem.style.fontSize = '24px';
      newITem.style.fontWeight = 'regular';
      if (listItem[0]) {
        listItem[0].parentNode.replaceChild(newITem, itemSelected);
      }     
    },
    changeColors(revertDefault){      
      let iconSelect=document.getElementsByClassName('material-icons-outlined');
      let commaSelection = document.querySelector('.v-select__selection.v-select__selection--comma');

      if(!revertDefault){
        if(iconSelect!=null && commaSelection != null){
          for (let i = 0; i < iconSelect.length; i++) {
            iconSelect[i].setAttribute('style', 'color: var(--v-primary-base) !important;');
          }
          commaSelection.setAttribute('style', 'color: var(--v-primary-base) !important;');
        }
      } else if(revertDefault){
        if(iconSelect!=null && commaSelection != null){
          for (let i = 0; i < iconSelect.length; i++) {
            iconSelect[i].setAttribute('style', 'color: #8686a2 !important;');
        }
          commaSelection.setAttribute('style', 'color: #8686a2 !important;');
        }
      }      
    },
    async exportar() {
      await this.exportAlerts();
      if (this.scheduleReport) {
        this.showToast(
          'Recebemos seu pedido com sucesso! Nos menus Agnes em: Documentos e Download. Assim que concluído, o arquivo estará disponível.',
          'success',
          78
        );
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },    
  },
};
</script>
<style scoped>
::v-deep .v-data-table {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
::v-deep #scrollDuplicate,
::v-deep .v-data-table__wrapper {
  overflow-x: auto !important;
}
::v-deep .v-data-footer__icons-after .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
}
::v-deep .v-data-footer__icons-before .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
}
::v-deep .v-data-table__wrapper table {
  max-height: 330px !important;
}
::v-deep #scrollDuplicate > div {
  height: 1px !important;
}
::v-deep .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot::after {
  transform: scaleX(0) !important;
}
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}
::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}
::v-deep .v-data-table__wrapper table thead.v-data-table-header tr th.text-start {
  border-bottom: none;
}
::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep .v-data-table tr td {
  border-bottom: 0px !important;
}

::v-deep .v-data-table tr td.text-start {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #52526b !important;
}
::v-deep .v-data-table tr.v-data-table__empty-wrapper {
  background-color: #f7f7fd !important;
}

::v-deep .v-data-footer__select {
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}

::v-deep .v-data-footer__select .v-select {
  margin: 16px 64px 16px 8px !important;
}
::v-deep .v-data-footer__pagination {
  margin: 0px 64px 0px 0px;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}
:v-deep v-btn .v-btn--disabled .v-btn--icon .v-btn--round .v-btn--text .theme--light .v-size--default {
  margin-right: 16px !important;
}

::v-deep .icon-action {
  margin: 0 !important;
  padding: 0 !important;
}

::v-deep .v-data-table tr td.text-start {
  cursor: default !important;
}
::v-deep .primary-default :hover {
  background-color: #e3ffee;
  width: 111%;
  height: 310%;
  border-radius: 3px;
  color: var(--v-primary-base);
}
i.mdi-information {
  height: 16px;
  width: 16px;
  color: #c73c44;
}
::v-deep .v-chip {
  color: var(--v-primary-base);
  font-size: 16px !important;
  font-weight: 'regular' !important;
  background-color: #f7f7fd !important;
  border: 1px solid #e7e7fa !important;
}
::v-deep .v-chip .v-chip__content {
  color: var(--v-primary-base);
  font-size: 16px !important;
  font-weight: 'regular' !important;
  background-color: #f7f7fd !important;
}
::v-deep .v-chip .v-icon {
  color: white !important;
  font-size: 16px !important;
  background-color: var(--v-primary-base) !important;
  border-radius: 100% !important;
  border: 4px solid white !important;
}
::v-deep .v-chip:hover .v-icon {
  color: #e3ffee !important;
  font-size: 16px !important;
  background-color: var(--v-primary-base) !important;
  border-radius: 100% !important;
  border: 4px solid white !important;
  opacity: 1;
}

i.mdi-check {
  height: 16px;
  width: 16px;
  color: #3cc79d;
}
::v-deep .v-input__slot::before {
  border: none !important;
}
::v-deep .v-select__slot {
  background-color: #e7e7fa;
  height: 48px;
  width: 83px;
  border-radius: 4px;
  border: 1px solid #e7e7fa;
  padding-left: 15px;
  padding-right: 10px;
}
::v-deep .v-select__slot .v-select__selections .v-select__selection {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8686a2 !important;
}
::v-deep .v-select__slot .v-input__append-inner {
  padding-top: 7px;
}
::v-deep .v-select__slot .v-select__selections input {
  background-color: #0606f8;
  color: #0606f8 !important;
  display: none;
}

::v-deep .v-data-footer {
  padding-left: 16px;
  padding-right: 16px;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-left: 0px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 8px !important;
  padding: 0;
}
.loaderCss {
  width: 39%;
  margin-top: 12px;
  margin-left: 15px;
  margin-bottom: 12px;
  height: 15px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  animation: shine 2s ease infinite;
}
@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
::v-deep
  .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select.v-input__control {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep .v-input__slot::before {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep
  .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select.v-input__control:hover {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}
::v-deep .v-data-footer .v-input__slot {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}

::v-deep .v-data-footer .v-data-footer__select .v-select {
  background: #ffffff !important;
  color: var(--v-primary-base);
  margin-top: 16px;
  margin-bottom: 0px;
}

::v-deep #tablea .v-select__selection {
  color: #8686a2;
}
::v-deep #tablea .v-input--is-label-active:not(.v-input--is-disabled) .v-select__selection {
  color: var(--v-primary-base);
}
::v-deep #tablea .v-input--is-label-active:not(.v-input--is-disabled) .v-select__selection:hover {
  background-color: #e3ffee !important;
}
::v-deep .v-select__selection--comma {
  overflow: visible !important;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1 !important;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transition: none !important;
}

::v-deep .v-data-footer__icons-before .v-btn--disabled {
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}
</style>
