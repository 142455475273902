var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    {
      staticStyle: {
        padding: "0px 0px 0px",
        border: "0px solid #e7e7fa !important",
      },
    },
    [
      _c(
        "v-card",
        {
          staticStyle: {
            border: "0px !important",
            "border-radius": "4px !important",
          },
        },
        [
          _c(
            "v-card-title",
            {
              staticStyle: {
                "background-color": "#f7f7fd",
                border: "1px solid #e7e7fa !important",
              },
            },
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tabs-slider", {
                    attrs: { color: _vm.isDisabled() ? "#F7F7FD" : "#00FF99" },
                  }),
                  _c(
                    "v-tab",
                    {
                      attrs: {
                        href: "#colaboradores",
                        value: "colaboradores",
                        id: "colaboradores",
                        disabled: _vm.isDisabled(),
                      },
                    },
                    [
                      _c("div", { staticClass: "tab-text" }, [
                        _vm._v(
                          _vm._s(_vm.funcTitle) +
                            " (" +
                            _vm._s(_vm.collaboratorsSize) +
                            ")"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      attrs: {
                        href: "#agentes-nocivos",
                        value: "agentes-nocivos",
                        id: "agentes-nocivos",
                        disabled: _vm.isDisabled(),
                      },
                    },
                    [
                      _c("div", { staticClass: "tab-text" }, [
                        _vm._v(
                          "Agentes Nocivos (" +
                            _vm._s(_vm.nociveAgentsSize) +
                            ")"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      attrs: {
                        href: "#alertas",
                        value: "alertas",
                        id: "alertas",
                        disabled: _vm.isDisabled(),
                      },
                    },
                    [
                      _c("div", { staticClass: "tab-text" }, [
                        _vm._v("Alertas (" + _vm._s(_vm.alertsSize) + ")"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              staticStyle: {
                "background-color": "#f7f7fd !important",
                border: "0px solid #e7e7fa !important",
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "colaboradores" } },
                [_c("TableColaboradores")],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "agentes-nocivos" } },
                [_c("TableAgentesNocivos")],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "alertas" } },
                [_c("TableAlertas")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }