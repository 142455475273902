var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-space-around",
      staticStyle: { border: "0px" },
    },
    [
      _c(
        "v-col",
        { staticClass: "pt-0 pl-0 pb-0 mb-4", attrs: { cols: "2" } },
        [
          _c(
            "v-card-title",
            { staticClass: "ml-0 pl-0", attrs: { id: "title" } },
            [_vm._v(" Filtro")]
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pt-0 pr-0 pb-0 mb-4", attrs: { cols: "10" } },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c("v-autocomplete", {
                style:
                  _vm.companiesList.length < 1
                    ? "background-color: #e7e7fa !important; height: 48px !important;border: 0px solid #CCCCCC;max-width: 256px !important; max-height; 48px !important;"
                    : "background-color: #ffffff; max-width: 256px  !important; max-height; 48px !important;height: 48px !important",
                attrs: {
                  disabled: _vm.companiesList.length < 1,
                  id: "inputCompany",
                  dense: "",
                  outlined: "",
                  "append-icon": "mdi-chevron-down",
                  multiple: "",
                  "hide-details": "",
                  "hide-no-data": "",
                  items: _vm.companiesList,
                  "item-text": (item) =>
                    item.subtitle ? item.subtitle : item.group,
                  "item-value": (item) => item,
                  label: "Empresa",
                  placeholder:
                    _vm.companiesList.length === 0 ? "Selecione uma opção" : "",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.buildStyle.apply(null, arguments)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function ({ index }) {
                      return [
                        index === 0
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.checkCompanies(_vm.companySelected)
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item",
                    fn: function ({ item, attrs, on }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              {
                                class: { "pl-10": item.group },
                                style: "max-width: 351px !important;",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ active }) {
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "input-value": active,
                                                  id: item.details
                                                    ? item.details.cnpj
                                                    : item.subtitle,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            { attrs: { id: "id" + attrs.id } },
                                            [
                                              item.socialName
                                                ? _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(item.socialName)
                                                    ),
                                                  ])
                                                : _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(item.subtitle)
                                                    ),
                                                  ]),
                                              item.details && item.details.cnpj
                                                ? _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("cnpj")(
                                                            item.details.cnpj
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.companySelected,
                  callback: function ($$v) {
                    _vm.companySelected = $$v
                  },
                  expression: "companySelected",
                },
              }),
              _c("v-autocomplete", {
                style:
                  _vm.companiesList.length < 1
                    ? "background-color: #e7e7fa !important; height: 48px !important;border: 0px solid #CCCCCC;max-width: 256px !important; margin-left:16px; max-height; 48px !important;"
                    : "background-color: #ffffff; max-width: 256px !important; margin-left:16px; max-height; 48px !important;height: 48px !important",
                attrs: {
                  disabled: _vm.companiesList.length < 1,
                  id: "inputCategory",
                  dense: "",
                  outlined: "",
                  "append-icon": "mdi-chevron-down",
                  multiple: "",
                  "hide-no-data": "",
                  label: "Categoria",
                  placeholder:
                    _vm.categorySelected.length === 0
                      ? "Selecione uma opção"
                      : "",
                  items: _vm.categoriesList,
                  "item-text": (item) =>
                    item.subtitle ? item.subtitle : _vm.concatenatedText(item),
                  "item-value": (item) => item,
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function ({ index }) {
                      return [
                        index === 0
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.checkCategories(_vm.categorySelected)
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item",
                    fn: function ({ item, attrs, on }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              {
                                class: item.grupo
                                  ? "item-descricao"
                                  : "item-subtitle",
                                style: "max-width: 351px !important;",
                                attrs: { id: "id" + attrs.id },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ active }) {
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "input-value": active,
                                                  id: item.codigo
                                                    ? item.codigo.toString()
                                                    : item.subtitle,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            { attrs: { id: attrs.id } },
                                            [
                                              item.descricao
                                                ? _c(
                                                    "v-list-item-title",
                                                    [
                                                      item.descricao.length > 28
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                "max-width": 250,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "span",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.start_and_end(
                                                                                      item.descricao,
                                                                                      28
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.descricao
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.descricao
                                                                )
                                                            ),
                                                          ]),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-list-item-title",
                                                    [
                                                      item.subtitle.length > 28
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "span",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.start_and_end(
                                                                                      item.subtitle,
                                                                                      28
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.subtitle
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.subtitle
                                                                )
                                                            ),
                                                          ]),
                                                    ],
                                                    1
                                                  ),
                                              item.codigo
                                                ? _c(
                                                    "v-list-item-subtitle",
                                                    {
                                                      staticClass:
                                                        "item-codigo",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Código " +
                                                          _vm._s(item.codigo) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.categorySelected,
                  callback: function ($$v) {
                    _vm.categorySelected = $$v
                  },
                  expression: "categorySelected",
                },
              }),
              _c("v-select", {
                staticClass: "ml-4 primary--text",
                style:
                  _vm.companiesList.length < 1
                    ? "background-color: #e7e7fa !important; height: 48px;border: 0px solid #CCCCCC;max-width: 256px;"
                    : "background-color: #ffffff;max-width: 256px;",
                attrs: {
                  disabled: _vm.companiesList.length < 1,
                  id: "fieldCompetencia",
                  "append-icon": "mdi-chevron-down",
                  dense: "",
                  outlined: "",
                  items: _vm.competencias,
                  "item-value": "value",
                  "item-text": "value",
                  label: "Competência",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.buildStyle.apply(null, arguments)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function ({ index }) {
                      return [
                        index === 0
                          ? _c("span", { staticClass: "primary--text" }, [
                              _vm._v(" " + _vm._s(_vm.competenciaSelect) + " "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item",
                    fn: function ({ item, attrs, on }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              {
                                class: { "pl-10": item.group },
                                attrs: { id: item.value },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ active }) {
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              item
                                                ? _c("v-list-item-title", [
                                                    _vm._v(_vm._s(item.label)),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.competenciaSelect,
                  callback: function ($$v) {
                    _vm.competenciaSelect = $$v
                  },
                  expression: "competenciaSelect",
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 green-hover",
                  attrs: {
                    id: "applyBtn",
                    height: "48px",
                    color: "primary",
                    disabled:
                      _vm.companiesList.length < 1 ||
                      (_vm.companySelected === null &&
                        _vm.competenciaSelect === null &&
                        _vm.categorySelected === null) ||
                      (_vm.companySelected != null &&
                        _vm.competenciaSelect === null &&
                        _vm.categorySelected === null) ||
                      (_vm.categorySelected != null &&
                        _vm.competenciaSelect != null &&
                        _vm.categorySelected === null) ||
                      _vm.applyBtnStatus,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.fetch()
                    },
                  },
                },
                [_vm._v(" Aplicar filtro")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 primary--text green-hover",
                  attrs: {
                    id: "clearBtn",
                    height: "48px",
                    color: "white",
                    disabled:
                      _vm.loading ||
                      (!this.competenciaSelect &&
                        (this.companySelected.length < 1 ||
                          this.categorySelected.length < 1)),
                  },
                  on: { click: _vm.clear },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: {
                        "max-width": "18px",
                        "max-height": "1px",
                        right: "6px",
                      },
                    },
                    [_vm._v("mdi-delete-outline")]
                  ),
                  _vm._v(" Limpar filtro"),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }