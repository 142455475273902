var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticStyle: { border: "1px solid #e7e7fa !important" } },
    [
      _c(
        "div",
        { attrs: { id: "alertas-container" } },
        [
          _c(
            "v-card-title",
            { staticClass: "pt-0 pl-0 pr-0" },
            [
              _c("TableSearch", {
                attrs: {
                  label: "Pesquisar por descrição",
                  id: "searchBarAlerts",
                  disabled: _vm.tableSearchDisabled,
                },
                on: { input: _vm.onInputTableAlertasSearch },
                model: {
                  value: _vm.filterCompetency.alertDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.filterCompetency, "alertDesc", $$v)
                  },
                  expression: "filterCompetency.alertDesc",
                },
              }),
              _c("v-spacer"),
              _c("ExportButton", {
                attrs: { "is-disabled": _vm.isDisabled, id: "btnExport" },
                on: { exportar: _vm.exportar },
              }),
            ],
            1
          ),
          _c("div", { attrs: { id: "scrollDuplicate" } }, [
            _c("div", { attrs: { id: "dummy" } }),
          ]),
          _c(
            "v-data-table",
            {
              staticClass: "pb-4",
              attrs: {
                id: "tablea",
                loading: _vm.loadingTable,
                search: _vm.search,
                headers: _vm.headers,
                options: _vm.pagination,
                "header-props": { sortIcon: null },
                items: _vm.alertas,
                page: _vm.pagination.page,
                "server-items-length": _vm.totalAlerts,
                "footer-props": {
                  disablePagination:
                    _vm.companiesList.length > 1 ? false : true,
                  itemsPerPageOptions:
                    _vm.totalAlerts < 50
                      ? [
                          { value: 50, text: "50" },
                          { value: 100, text: "100", disabled: true },
                          { value: 250, text: "250", disabled: true },
                        ]
                      : _vm.totalAlerts < 100
                      ? [
                          { value: 50, text: "50" },
                          { value: 100, text: "100" },
                          { value: 250, text: "250", disabled: true },
                        ]
                      : [
                          { value: 50, text: "50" },
                          { value: 100, text: "100" },
                          { value: 250, text: "250" },
                        ],
                  disableItemsPerPage: _vm.alertas.length === 0,
                  itemsPerPageText: "Alertas por página: ",
                  expandIcon: "mdi-dots-horizontal",
                },
                "no-data-text":
                  _vm.companiesList.length === 0
                    ? "Não há alertas a serem exibidos."
                    : "Verifique se há alertas a serem exibidos. Selecione uma competência e aplique o filtro.",
              },
              on: {
                "update:options": function ($event) {
                  _vm.pagination = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer.page-text",
                  fn: function (items) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(items.pageStart) +
                          " - " +
                          _vm._s(items.pageStop) +
                          " de " +
                          _vm._s(items.itemsLength) +
                          " alertas "
                      ),
                    ]
                  },
                },
                {
                  key: "item.socialName",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.socialName) + " ")]
                  },
                },
                {
                  key: "item.cnpj",
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " "),
                    ]
                  },
                },
                {
                  key: "item.desc",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.desc) + " ")]
                  },
                },
                {
                  key: "item.lastUpdate",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dateAsTime")(item.lastUpdate)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.loadingTable
                ? _c("template", { slot: "body" }, [
                    _c(
                      "tr",
                      _vm._l(_vm.headers, function (loadItem) {
                        return _c(
                          "td",
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "loaderCss",
                              attrs: { "max-width": loadItem.width },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }