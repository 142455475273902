<template>
  <v-card style="border: 1px solid #e7e7fa !important">
    <div id="nocive-agents-container">
      <v-card-title class="pl-0 pr-0 pt-0">
        <TableSearch
          id="searchBardAgNociv"
          v-model="filterCompetency.nociveAgentCodeDesc"
          label="Pesquisar por código ou agente nocivo"
          :disabled="tableSearchDisabled"
          @input="onInputTableAgentesNocivosSearch"
        >
        </TableSearch>
        <v-spacer></v-spacer>

        <ExportButton id="exportBtn" :is-disabled="isDisabled" @exportar="exportar" />
      </v-card-title>
      <div id="scrollDuplicate">
        <div id="dummy"></div>
      </div>
      <v-data-table
        class="pb-4"
        :loading="loadingTable"
        :search="search"
        :headers="headers"
        :options.sync="pagination"
        :header-props="{ sortIcon: null }"
        :items="nociveAgents"
        :page="pagination.page"
        :server-items-length="totalNociveAgents"
        :footer-props="{
          disablePagination: companiesList.length > 1 ? false : true,
          itemsPerPageOptions:
            totalNociveAgents < 50
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100', disabled: true },
                  { value: 250, text: '250', disabled: true },
                ]
              : totalNociveAgents < 100
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250', disabled: true },
                ]
              : [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250' },
                ],
          disableItemsPerPage: nociveAgents.length === 0,
          itemsPerPageText: 'Agentes nocivos por página: ',
          expandIcon: 'mdi-dots-horizontal',
        }"
        ><template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} agentes nocivos
        </template>
        <template v-if="loadingTable" slot="body">
          <tr>
            <td v-for="loadItem in headers">
              <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
            </td>
          </tr>
        </template>
        <template v-slot:item.socialName="{ item }"> {{ item.socialName }} </template>
        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj | cnpj }}
        </template>
        <template v-slot:item.code="{ item }">
          {{ item.code }}
        </template>
        <template v-slot:header.collaborators="{ header }">
          <span class="mr-2 align-center"> Trabalhadores</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <span>Número de {{ funcTitle }} Expostos ao Agente Nocivo</span>
          </v-tooltip>
        </template>
        <template v-slot:item.consideredLimit="{ item }">
          <div class="d-flex flex-row">
            <span
              v-if="item.consideredLimit === 'Não se aplica a agentes nocivos qualitativos.'"
              class="icon-nocive-agents"
            >
              <v-icon>error_outline</v-icon>
            </span>
            <span>{{ item.consideredLimit }}</span>
          </div>
        </template>
        <template v-slot:item.desc="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ start_and_end(item.desc, 32) }}
              </span>
            </template>
            <span>{{ item.desc }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.lastUpdate="{ item }">
          {{ item.lastUpdate | dateAsTime }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            :id="'btn-action-' + item.id"
            class="btn-action"
            icon
            :disabled="nociveAgents.length === 0"
            @click="showModal(item)"
          >
            <v-icon>mdi-file-document-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <ModalAgentesNocivos ref="modalAgentesNocivos" :item="itemAgentNocive" />
  </v-card>
</template>
<script>
import { eventBus } from '@/main.js';
import { mapGetters, mapActions } from 'vuex';

import ExportButton from './ExportButton.vue';

export default {
  name: 'TableAgentesNocivos',

  components: {
    ModalAgentesNocivos: () => import('./ModalAgentesNocivos.vue'),
    TableSearch: () => import('../../commons/TableSearch.vue'),
    ExportButton,
  },

  data() {
    return {
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },

      funcTitle: process.env.VUE_APP_TAGFUNC,
      search: '',
      totalAgentesNocivos: 0,
      headers: [
        { text: 'Empresa', value: 'socialName', width: '388px', sortable: true },
        { text: 'CNPJ', value: 'cnpj', width: '184px', sortable: true },
        { text: 'Código', value: 'code', width: '78px', sortable: true },
        { text: 'Nome', value: 'desc', width: '344px', sortable: true },
        { text: 'toAlter', value: 'collaborators', width: '126px', sortable: true },
        { text: 'Limite de tolerância', value: 'consideredLimit', width: '190px', sortable: true },
        { text: 'Criério de avaliação', value: 'evaluationCriteria', width: '190px', sortable: true },
        { text: 'Menor limite registrado', value: 'minorRegisteredLimit', width: ' 190px', sortable: true },
        { text: 'Maior limite registrado', value: 'majorRegisteredLimit', width: ' 190px', sortable: true },
        { text: 'Atualização', value: 'lastUpdate', width: '185px', sortable: true },
        { text: 'Ações', value: 'action', width: '72px', sortable: false },
      ],

      itemAgentNocive: null,
      customStyleSkeleton:
        'text-align: start;color: #e7e7fa;font-family: "Roboto";font-weight: 700;font-size: 28px;letter-spacing: 1%;height: 51px;flex-direction: row;align-items: flex-start; border-left: 2px solid #ffffff',
    };
  },

  watch: {
    nociveAgents(value) {
      if (value.length === 0) {
        this.search = '';
        this.changeColors(true);
      } else {
        this.changeColors(false);
      }
    },
  },

  updated() {
    this.customize();
    this.changeIcon();
  },

  mounted() {
    this.updateScrollers();
    window.addEventListener('resize', this.updateScrollers);

    const linkScroller = (a, b) => {
      a.addEventListener('scroll', (e) => {
        b.scrollLeft = e.target.scrollLeft;
      });
    };
    const _a = this.$el.querySelector('#scrollDuplicate');
    const _b = this.$el.querySelector('.v-data-table__wrapper');
    linkScroller(_a, _b);
    linkScroller(_b, _a);
  },

  created() {},
  computed: {
    ...mapGetters({
      companiesList: 'wss/companiesList',
      nociveAgents: 'wss/getNociveAgents',
      loading: 'wss/getAllDataLoaded',
      loadingTable: 'wss/getLoadingTableAgNocivo',
      paginationFilter: 'wss/getPaginationNocAg',
      totalNociveAgents: 'wss/getTotalNociveAgents',
      filterCompetency: 'wss/getCountNociveAgentFilter',
      nociveAgentBlob: 'wss/getNociveAgentBlob',
      scheduleReport: 'wss/getScheduleReport',
    }),

    isDisabled() {
      if (this.nociveAgents.length < 1) {
        return true;
      } else if (this.companiesList.length < 1) {
        return true;
      } else if (this.filterCompetency.competencyIn === '') {
        return true;
      } else if (this.loading) {
        return true;
      } else {
        return false;
      }
    },

    pagination: {
      get: function () {
        return this.paginationFilter;
      },

      set: function (value) {
        this.setPaginationNocAg(value);
      },
    },

    tableSearchDisabled() {
      return this.loading;
    },
  },

  methods: {
    ...mapActions('wss', [
      'getCompanies',
      'collaboratorRetirementRisks',
      'setPaginationNocAg',
      'searchNociveAgent',
      'exportNociveAgents',
      'changeNociveAgent',
      'specificFilter',
    ]),

    async exportar() {
      await this.exportNociveAgents();
      if (this.scheduleReport) {
        this.showToast(
          'Recebemos seu pedido com sucesso! Nos menus Agnes em: Documentos e Download. Assim que concluído, o arquivo estará disponível.',
          'success',
          78,
        );
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },

    updateScrollers() {
      const dummy = this.$el.querySelector('#dummy');
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      dummy.style.width = _b.scrollWidth + 'px';
    },

    onInputTableAgentesNocivosSearch(val) {
      this.searchNociveAgent(val);
    },

    customize() {
      let tableLines = document.querySelectorAll('#nocive-agents-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },

    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },

    showModal(item) {
      this.changeNociveAgent(item.desc);
      this.specificFilter();
      this.itemAgentNocive = item;
      this.showAction = true;
      this.$refs.modalAgentesNocivos.$emit('open');
      let btnAction = document.getElementById('btn-action-' + item.id);
      btnAction.style.backgroundColor = '#00ff99';
    },

    changeIcon() {
      const isDisabledOrNot = document.getElementsByClassName(
        'v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--is-disabled theme--light v-text-field v-text-field--is-booted v-select',
      );

      if (isDisabledOrNot[0]) {
        const listItem = document.getElementsByClassName(
          'v-icon notranslate v-icon--disabled mdi mdi-menu-down theme--light',
        );

        const itemSelected = listItem[0];

        const newITem = document.createElement('span');
        newITem.setAttribute('class', 'material-icons-outlined');
        newITem.innerText = 'expand_more';

        newITem.style.color = 'var(--v-primary-base)';
        newITem.style.fontSize = '24px';
        newITem.style.fontWeight = 'regular';
        if (listItem[0]) {
          let iconSelect = document.getElementById('material-icons-outlined');
          if (iconSelect == null) {
            newITem.style.color = '#8686A2';
          }
          listItem[0].parentNode.replaceChild(newITem, itemSelected);
        }
      } else {
        const listItem = document.getElementsByClassName('material-icons-outlined');

        let selected = '';
        if (listItem[4] != undefined && listItem[4].innerHTML === 'expand_more') {
          //<!-- const itemSelected = listItem[4]; -->
          selected = listItem[4];
          selected.style.color = 'var(--v-primary-base)';
          selected.style.fontSize = '24px';
          selected.style.fontWeight = 'regular';
        }
      }
    },

    changeColors(revertDefault) {
      let iconSelect = document.getElementsByClassName('material-icons-outlined');
      let commaSelection = document.querySelector('.v-select__selection.v-select__selection--comma');

      if (!revertDefault) {
        if (iconSelect != null && commaSelection != null) {
          for (let i = 0; i < iconSelect.length; i++) {
            iconSelect[i].setAttribute('style', 'color: var(--v-primary-base) !important;');
          }
          commaSelection.setAttribute('style', 'color: var(--v-primary-base) !important;');
        }
      } else if (revertDefault) {
        if (iconSelect != null && commaSelection != null) {
          for (let i = 0; i < iconSelect.length; i++) {
            iconSelect[i].setAttribute('style', 'color: #8686a2 !important;');
          }
          commaSelection.setAttribute('style', 'color: #8686a2 !important;');
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep #scrollDuplicate,
::v-deep .v-data-table__wrapper {
  overflow-x: auto !important;
}

::v-deep .v-data-table__wrapper table {
  max-height: 330px !important;
  width: 200vw !important;
}

::v-deep #scrollDuplicate > div {
  height: 1px !important;
}
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}
::v-deep .v-data-footer__icons-after .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
}
::v-deep .v-data-footer__icons-before .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
}
::v-deep .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot::after {
  transform: scaleX(0) !important;
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}
::v-deep .v-data-table__wrapper table thead.v-data-table-header tr th.text-start {
  border-bottom: none;
}
::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep .v-data-table tr td.text-start {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #52526b !important;
}

::v-deep .v-data-footer__select {
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}

::v-deep .v-data-footer__select .v-select {
  margin: 16px 64px 16px 8px !important;
}
::v-deep .v-data-footer__pagination {
  margin: 0px 64px 0px 0px;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}
:v-deep v-btn .v-btn--disabled .v-btn--icon .v-btn--round .v-btn--text .theme--light .v-size--default {
  margin-right: 16px !important;
}
::v-deep .icon-action {
  margin: 0 !important;
  padding: 0 !important;
}

::v-deep .secundary-default {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

::v-deep .secundary-default :hover {
  background: #e3ffee;
  width: 143%;
  height: 220%;
  color: var(--v-primary-base);
  border-radius: 5px;
}
::v-deep .v-data-table tr td {
  border-bottom: 0px !important;
}
::v-deep .v-data-table tr td.text-start {
  cursor: default !important;
}

::v-deep .secundary-default-active {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  color: #fff !important;
  background-color: var(--v-primary-base) !important;
}

::v-deep .secundary-default-active :hover {
  background: #e3ffee;
  width: 143%;
  height: 230%;
  color: var(--v-primary-base);
  border-radius: 5px;
}

::v-deep .primary-default :hover {
  background-color: #e3ffee;
  width: 111%;
  height: 310%;
  border-radius: 3px;
  color: var(--v-primary-base);
}
.btn-action {
  color: var(--v-primary-base);
  width: 32px;
  height: 32px;
  border-radius: 32px !important;
}
span.v-btn__content {
  width: 24px !important;
  height: 24px !important;
}
i.v-icon {
  width: 14px !important;
  height: 14px !important;
}
.btn-action:hover {
  background-color: #e3ffee !important;
}
.btn-action:disabled {
  color: #8682a2;
  background-color: #e7e7fa;
}
.large-table-container-1 {
  max-width: 200px;
  overflow-x: scroll;
  overflow-y: auto;
}
::v-deep .v-input__slot::before {
  border: none !important;
}
::v-deep .v-select__slot {
  background-color: #e7e7fa;
  height: 48px;
  width: 83px;
  border-radius: 4px;
  border: 1px solid #e7e7fa;
  padding-left: 15px;
  padding-right: 10px;
}
::v-deep .v-select__slot .v-select__selections .v-select__selection {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8686a2 !important;
}
::v-deep .v-select__slot .v-input__append-inner {
  padding-top: 7px;
}
::v-deep .v-select__slot .v-select__selections input {
  background-color: #0606f8;
  color: #0606f8 !important;
  display: none;
}

::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: #8686a2 !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: #e7e7fa;
  color: #8686a2 !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 0;
  padding: 0;
}

::v-deep .v-select__selection--comma {
  overflow: visible !important;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1 !important;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transition: none !important;
}

::v-deep .v-data-footer__icons-before .v-btn--disabled {
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
}

.icon-nocive-agents {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 0 solid;
  border-radius: 100%;
  justify-content: center;
  background-color: #e7e7fa;
  margin-right: 16px;
}

.loaderCss {
  margin: 12px;
  width: 100%;
  height: 15px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
