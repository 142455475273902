var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pl-4 pt-4 pr-4 pb-4", attrs: { id: "cardBars" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "titleColor--text",
          staticStyle: {
            width: "600px",
            "padding-top": "0px",
            "padding-left": "0px",
          },
          attrs: { id: "cardTitle" },
        },
        [
          _vm._v(" " + _vm._s(_vm.titleForCard) + " "),
          _c("v-spacer"),
          _c(
            "v-tooltip",
            {
              attrs: { "max-width": "550px", bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            { staticClass: "icon-button" },
                            "div",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "vetor",
                              attrs: {
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M12.1 17.825C12.3667 17.825 12.5917 17.7333 12.775 17.55C12.9583 17.3667 13.05 17.1417 13.05 16.875C13.05 16.6083 12.9583 16.3833 12.775 16.2C12.5917 16.0167 12.3667 15.925 12.1 15.925C11.8333 15.925 11.6083 16.0167 11.425 16.2C11.2417 16.3833 11.15 16.6083 11.15 16.875C11.15 17.1417 11.2417 17.3667 11.425 17.55C11.6083 17.7333 11.8333 17.825 12.1 17.825ZM11.225 14.175H12.7C12.7 13.7417 12.7542 13.3458 12.8625 12.9875C12.9708 12.6292 13.3083 12.2167 13.875 11.75C14.3917 11.3167 14.7583 10.8917 14.975 10.475C15.1917 10.0583 15.3 9.6 15.3 9.1C15.3 8.21667 15.0125 7.50833 14.4375 6.975C13.8625 6.44167 13.1 6.175 12.15 6.175C11.3333 6.175 10.6125 6.37917 9.9875 6.7875C9.3625 7.19583 8.90833 7.75833 8.625 8.475L9.95 8.975C10.1333 8.50833 10.4083 8.14583 10.775 7.8875C11.1417 7.62917 11.575 7.5 12.075 7.5C12.6417 7.5 13.1 7.65417 13.45 7.9625C13.8 8.27083 13.975 8.66667 13.975 9.15C13.975 9.51667 13.8667 9.8625 13.65 10.1875C13.4333 10.5125 13.1167 10.85 12.7 11.2C12.2 11.6333 11.8292 12.0625 11.5875 12.4875C11.3458 12.9125 11.225 13.475 11.225 14.175ZM12 22C10.6 22 9.29167 21.7458 8.075 21.2375C6.85833 20.7292 5.8 20.025 4.9 19.125C4 18.225 3.29167 17.1667 2.775 15.95C2.25833 14.7333 2 13.4167 2 12C2 10.6 2.25833 9.29167 2.775 8.075C3.29167 6.85833 4 5.8 4.9 4.9C5.8 4 6.85833 3.29167 8.075 2.775C9.29167 2.25833 10.6 2 12 2C13.3833 2 14.6833 2.25833 15.9 2.775C17.1167 3.29167 18.175 4 19.075 4.9C19.975 5.8 20.6875 6.85833 21.2125 8.075C21.7375 9.29167 22 10.6 22 12C22 13.4167 21.7375 14.7333 21.2125 15.95C20.6875 17.1667 19.975 18.225 19.075 19.125C18.175 20.025 17.1167 20.7292 15.9 21.2375C14.6833 21.7458 13.3833 22 12 22ZM12 20.5C14.35 20.5 16.3542 19.6667 18.0125 18C19.6708 16.3333 20.5 14.3333 20.5 12C20.5 9.66667 19.6708 7.66667 18.0125 6C16.3542 4.33333 14.35 3.5 12 3.5C9.61667 3.5 7.60417 4.33333 5.9625 6C4.32083 7.66667 3.5 9.66667 3.5 12C3.5 14.3333 4.32083 16.3333 5.9625 18C7.60417 19.6667 9.61667 20.5 12 20.5Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("TooltipAgentesNocivosEAposentariaEspecial", {
                staticStyle: {
                  position: "absolute",
                  left: "-519px",
                  top: "-10px",
                },
                attrs: { "align-items": "left" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrapper d-flex justify-center" },
        [
          _vm.serieLength >= 1 && !_vm.loading
            ? _c("apexCharts", {
                staticClass: "my-n3",
                attrs: {
                  id: "graphSpecialRetirement",
                  type: "donut",
                  width: "324",
                  options: _vm.chartOptions,
                  series: _vm.series,
                },
              })
            : _vm._e(),
          _vm.loading
            ? _c("LoadingCircular", { staticClass: "my-8" })
            : _vm._e(),
          !_vm.loading && _vm.serieLength === 0
            ? _c("NoData", { attrs: { type: "donut", width: "324" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }