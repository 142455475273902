<template>
  <div class="d-flex justify-space-around" style="border: 0px">
    <v-col cols="2" class="pt-0 pl-0 pb-0 mb-4">
      <v-card-title id="title" class="ml-0 pl-0"> Filtro</v-card-title></v-col
    >
    <v-col cols="10" class="pt-0 pr-0 pb-0 mb-4">
      <div class="d-flex justify-end">
        <v-autocomplete
          :disabled="companiesList.length < 1"
          id="inputCompany"
          :style="
            companiesList.length < 1
              ? 'background-color: #e7e7fa !important; height: 48px !important;border: 0px solid #CCCCCC;max-width: 256px !important; max-height; 48px !important;'
              : 'background-color: #ffffff; max-width: 256px  !important; max-height; 48px !important;height: 48px !important'
          "
          dense
          outlined
          append-icon="mdi-chevron-down"
          multiple
          hide-details
          hide-no-data
          :items="companiesList"
          :item-text="(item) => (item.subtitle ? item.subtitle : item.group)"
          :item-value="(item) => item"
          v-model="companySelected"
          label="Empresa"
          :placeholder="companiesList.length === 0 ? 'Selecione uma opção' : ''"
          @click.prevent="buildStyle"
        >
          <!-- <template v-slot:selection="{ item }">
            <span
              v-if="item"
              class="primary--text"
              style="max-height: 40px !important; max-width: 317px !important; width: 317px !important"
            >
              {{ `${item.subtitle ? item.subtitle : item.group}` }}
            </span>
          </template> -->
          <template v-slot:selection="{ index }">
            <span v-if="index === 0">
              {{ checkCompanies(companySelected) }}
            </span>
          </template>
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item
              :style="'max-width: 351px !important;'"
              #default="{ active }"
              v-bind="attrs"
              :class="{ 'pl-10': item.group }"
              v-on="on"
            >
              <v-list-item-action>
                <v-checkbox :input-value="active" :id="item.details ? item.details.cnpj : item.subtitle"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content :id="'id' + attrs.id">
                <v-list-item-title v-if="item.socialName">{{ item.socialName }}</v-list-item-title>
                <v-list-item-title v-else>{{ item.subtitle }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.details && item.details.cnpj">
                  {{ item.details.cnpj | cnpj }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-autocomplete
          :disabled="companiesList.length < 1"
          id="inputCategory"
          :style="
            companiesList.length < 1
              ? 'background-color: #e7e7fa !important; height: 48px !important;border: 0px solid #CCCCCC;max-width: 256px !important; margin-left:16px; max-height; 48px !important;'
              : 'background-color: #ffffff; max-width: 256px !important; margin-left:16px; max-height; 48px !important;height: 48px !important'
          "
          dense
          outlined
          append-icon="mdi-chevron-down"
          multiple
          hide-no-data
          v-model="categorySelected"
          label="Categoria"
          :placeholder="categorySelected.length === 0 ? 'Selecione uma opção' : ''"
          :items="categoriesList"
          :item-text="(item) => (item.subtitle ? item.subtitle : concatenatedText(item))"
          :item-value="(item) => item"
        >
          <template v-slot:selection="{ index }">
            <span v-if="index === 0">
              {{ checkCategories(categorySelected) }}
            </span>
          </template>

          <template v-slot:item="{ item, attrs, on }">
            <v-list-item
              :style="'max-width: 351px !important;'"
              #default="{ active }"
              v-bind="attrs"
              :id="'id' + attrs.id"
              :class="item.grupo ? 'item-descricao' : 'item-subtitle'"
              v-on="on"
            >
              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  :id="item.codigo ? item.codigo.toString() : item.subtitle"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content :id="attrs.id">
                <v-list-item-title v-if="item.descricao">
                  <v-tooltip bottom v-if="item.descricao.length > 28" :max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ start_and_end(item.descricao, 28) }}
                      </span>
                    </template>
                    {{ item.descricao }}
                  </v-tooltip>
                  <span v-else> {{ item.descricao }}</span>
                </v-list-item-title>
                <v-list-item-title v-else>
                  <v-tooltip bottom v-if="item.subtitle.length > 28">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"> {{ start_and_end(item.subtitle, 28) }} </span>
                    </template>
                    {{ item.subtitle }}
                  </v-tooltip>
                  <span v-else> {{ item.subtitle }}</span>
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.codigo" class="item-codigo">
                  Código {{ item.codigo }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-select
          :disabled="companiesList.length < 1"
          id="fieldCompetencia"
          class="ml-4 primary--text"
          append-icon="mdi-chevron-down"
          :style="
            companiesList.length < 1
              ? 'background-color: #e7e7fa !important; height: 48px;border: 0px solid #CCCCCC;max-width: 256px;'
              : 'background-color: #ffffff;max-width: 256px;'
          "
          dense
          outlined
          v-model="competenciaSelect"
          :items="competencias"
          item-value="value"
          item-text="value"
          label="Competência"
          @click.prevent="buildStyle"
        >
          <template v-slot:selection="{ index }">
            <span v-if="index === 0" class="primary--text">
              {{ competenciaSelect }}
            </span>
          </template>
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item
              #default="{ active }"
              v-bind="attrs"
              :class="{ 'pl-10': item.group }"
              v-on="on"
              :id="item.value"
            >
              <v-list-item-content>
                <v-list-item-title v-if="item">{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>

        <v-btn
          id="applyBtn"
          height="48px"
          color="primary"
          class="ml-4 green-hover"
          :disabled="
            companiesList.length < 1 ||
            (companySelected === null && competenciaSelect === null && categorySelected === null) ||
            (companySelected != null && competenciaSelect === null && categorySelected === null) ||
            (categorySelected != null && competenciaSelect != null && categorySelected === null) ||
            applyBtnStatus
          "
          @click="fetch()"
        >
          Aplicar filtro</v-btn
        >

        <v-btn
          id="clearBtn"
          class="ml-4 primary--text green-hover"
          height="48px"
          color="white"
          :disabled="
            loading ||
            (!this.competenciaSelect && (this.companySelected.length < 1 || this.categorySelected.length < 1))
          "
          @click="clear"
          ><v-icon style="max-width: 18px; max-height: 1px; right: 6px">mdi-delete-outline</v-icon> Limpar filtro</v-btn
        >
      </div></v-col
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';

export default {
  name: 'FiltroPrimario',

  data() {
    return {
      countNociveAgentsFilter: {
        cnpjs: [],
        competencyIn: '',
      },
      readOnly: false,

      search2: null,
      applyBtnDisabled: false,
    };
  },
  created() {
    this.clear();
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      companiesList: 'wss/companiesList',
      categoriesList: 'wss/categoriesList',
      applyBtnStatus: 'wss/getApplyBtnStatus',
      loading: 'wss/getAllDataLoaded',
      competenciaSelecionada: 'wss/getCompetencyInSelected',
      empresaSelecionada: 'wss/getEmpresaSelecionada',
      categoriaSelecionada: 'wss/geCategoriaSelecionada',
      competencias: 'wss/getCompetencias',
    }),
    competenciaSelect: {
      get: function () {
        return this.competenciaSelecionada;
      },
      set: function (value) {
        this.changeCompetencia(value);
      },
    },
    companySelected: {
      get: function () {
        return this.empresaSelecionada;
      },
      set: function (value) {
        this.changeCompany(value);
      },
    },
    categorySelected: {
      get: function () {
        return this.categoriaSelecionada;
      },
      set: function (value) {
        this.changeCategory(value);
      },
    },
  },
  methods: {
    ...mapActions('wss', [
      'getCompanies',
      'changeApplyBtnStatus',
      'changeCompany',
      'changeCategory',
      'cleanFilters',
      'changeCompetencia',
      'applyFilter',
    ]),
    turnOnOff(bool) {
      this.readOnly = bool;
    },
    checkCompanies(companySelected) {
      this.cleanPlaceholder('#inputCompany');

      let totalCompanies = 0;
      for (let i = 0; i < companySelected.length; i++) {
        const c = companySelected[i];
        if (c.subtitle) {
          totalCompanies++;
        }
      }
      if (totalCompanies > 0) {
        return `${companySelected.length - totalCompanies} selecionados`;
      } else {
        return `${companySelected.length} selecionados`;
      }
    },
    checkCategories(categorySelected) {
      this.cleanPlaceholder('#inputCategory');

      let totalCategories = 0;
      for (let i = 0; i < categorySelected.length; i++) {
        const c = categorySelected[i];
        if (c.subtitle) {
          totalCategories++;
        }
      }
      if (totalCategories > 0) {
        return `${categorySelected.length - totalCategories} selecionados`;
      } else {
        return `${categorySelected.length} selecionados`;
      }
    },
    cleanPlaceholder(nameInpu) {
      const inputElement = document.querySelector(nameInpu);
      if (inputElement) {
        inputElement.placeholder = '';
      }
    },
    async fetch() {
      this.applyFilter(true);
      this.$root.$emit('enableCollaboratorsSearch');
    },
    clear() {
      this.$root.$emit('clearCollaboratorsFilter', true);
      this.$root.$emit('disableCollaboratorsSearch');
      let x = document.getElementsByClassName('v-icon notranslate mdi mdi-checkbox-marked theme--light primary--text');
      if (x.length > 0) {
        x[0].className = 'v-icon notranslate mdi mdi-checkbox-blank-outline theme--light';
      }
      this.cleanFilters();
    },

    init() {
      this.changeApplyBtnStatus(true);
    },
    async buildStyle() {
      await this.sleep(30);
      let content = null;
      while (!content) {
        content = document.querySelector('.v-menu__content.theme--light.menuable__content__active');
        await this.sleep(30);
      }
      content.style.top = '424px';
    },
    async sleep(ms) {
      await new Promise((r) => setTimeout(r, ms));
    },
    start_and_end(str, lengthStr) {
      str = this.removeText('Contribuinte individual - ', str);
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
    removeText(textForRemove, str) {
      return str.replace(new RegExp(textForRemove, 'g'), '');
    },
    concatenatedText(obj) {
      let concatenatedText = '';

      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'string') {
            concatenatedText += value;
          }
          if (typeof value === 'number') {
            concatenatedText += value.toString();
          }
        }
      }
      return concatenatedText.toLowerCase();
    },
    itensSelectCategories(categorySelected) {
      let lengthCategory = categorySelected.filter((e) => e.grupo).length;

      if (lengthCategory === 1 && categorySelected[0].grupo) {
        return `(${categorySelected[0].qtdColaboradores}) ${categorySelected[0].descricao}`;
      }
      if (lengthCategory === 1 && categorySelected[0].subtitle) {
        return `(${categorySelected[1].qtdColaboradores}) ${categorySelected[1].descricao}`;
      }

      return `${lengthCategory} selecionado${lengthCategory > 1 ? 's' : ''}`;
    },
  },
  beforeMount() {
    this.changeCompetencia('');
    this.init();
  },
};
</script>

<style scoped>
#title {
  padding-top: 0px;
  margin-left: -12px;
  margin-top: -4px;
  font-size: 20px !important;
  font-weight: bold !important;
  color: #182026 !important;
}

#clearBtn:enabled {
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  max-width: 154px !important;
}
#clearBtn:disabled {
  font-size: 16px !important;
  color: #8686a2 !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  background-color: #e7e7fa !important;
  max-width: 154px !important;
}
#applyBtn:enabled {
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  max-width: 140px !important;
}
#applyBtn:hover {
  background-color: #e3ffee !important;
}
#applyBtn:disabled {
  font-size: 16px !important;
  color: #8686a2 !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  background-color: #e7e7fa !important;
  max-width: 140px !important;
}
#fieldCompetencia:disabled {
  color: #8686a2 !important;
}
::v-deep .v-autocomplete {
  max-width: 317px !important;
  max-height: 48px !important;
}
::v-deep .v-list-item:hover {
  background-color: #e3ffee !important;
}
::v-deep .v-list-item--link::before {
  background-color: none;
}
::v-deep .v-select__selections {
  width: 317px !important;
  height: 48px !important;
  display: flex;
  /* overflow: hidden !important; */
}

::v-deep .v-select__selections span {
  color: #182026;
}
::v-deep .v-select__selection {
  color: var(--v-primary-base) !important ;
  width: 317px !important;
  height: 48px !important;
  display: flex;
  /* overflow: hidden !important; */
}
::v-deep .v-text-field__details {
  margin-bottom: 0 !important;
}
::v-deep .v-messages {
  max-height: 16px !important;
}
.green-hover :hover {
  background: #e3ffee;
  width: 134%;
  height: 252%;
  color: var(--v-primary-base);
  border-radius: 5px;
  height: 48px;
}
::v-deep .v-input ::placeholder {
  color: #182026 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
::v-deep .v-input__control {
  max-height: 48px;
}
::v-deep .v-input__control :hover {
  background: #e3ffee;
}
::v-deep .v-input--is-disabled .v-input__control :hover {
  background: transparent !important;
}
::v-deep .v-input--is-disabled .v-input__control .v-input__slot:hover fieldset {
  color: #b0b0bf !important;
}
::v-deep .v-input__slot {
  min-height: 48px !important;
  border-radius: 4px;
  border-color: #8686a2;
}
::v-deep .v-input__slot :hover {
  background: transparent;
}

::v-deep .v-list-item__action {
  margin: 0;
}
::v-deep .v-label {
  color: #52526b !important;
  font-size: 12px;
}
::v-deep .v-label.v-label--active {
  color: var(--v-primary-base);
}
::v-deep .v-icon.mdi-chevron-down {
  color: var(--v-primary-base);
}
::v-deep .v-input__control .v-input__slot:hover fieldset {
  color: #e7e7fa !important;
}
.item-subtitle {
  background-color: #f7f7fd;
  color: #182026;
}
.item-subtitle span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.item-descricao {
  padding-left: 40px !important;
  color: #182026;
}
.item-descricao span {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
.item-descricao-inative {
  padding-left: 40px !important;
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
}
.item-subtitle-inative {
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.item-subtitle-inative span {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.item-codigo {
  color: #52526b !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.v-list-item__subtitle.item-codigo {
  color: #52526b !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.v-list-item__subtitle.item-codigo-inative {
  color: #8686a2 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
::v-deep .mdi-checkbox-blank-outline {
  color: var(--v-primary-base);
}
</style>
