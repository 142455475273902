var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticStyle: { "background-color": "#f7f7fd" } },
    [
      _c("HeaderAgentesNocivosEAposentariaEspecial", {
        ref: "headerAgentesNocivosEAposentariaEspecial",
      }),
      _c(
        "v-card",
        {
          staticClass: "d-flex flex-column",
          attrs: { id: "cardDash", elevation: "0" },
        },
        [
          _c("FiltroPrimario"),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between mb-4" },
            [_c("GraficoAposentadoriaEspecial"), _c("GraficoAgentesNocivos")],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-space-around" },
            [
              _c(
                "v-col",
                { staticStyle: { padding: "0px !important" } },
                [_c("TabsAgentesNocivosEAposentariaEspecial")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }