var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "cardAgents" },
        [
          _c(
            "v-card-title",
            { staticClass: "titleStyle" },
            [
              _c("v-col", { staticClass: "ml-n4", attrs: { cols: "11" } }, [
                _vm._v(" " + _vm._s(_vm.colaboratorCopy.name)),
              ]),
              _c(
                "v-col",
                { staticClass: "ml-4", attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "btnCloseX", icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "cardScroll2" } },
            _vm._l(
              _vm.filterItem(_vm.colaboratorCopy.nociveAgents),
              function (h) {
                return _c(
                  "div",
                  { key: h.id, staticClass: "cardForEachAgent" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "containerForText",
                        attrs: { id: "idAgNociveTitle" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "d-flex justify-start subHeaderTitle",
                          },
                          [_vm._v("Agente nocivo ")]
                        ),
                        h.desc.lenght < 42
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex justify-start subHeaderSubtitle",
                              },
                              [_vm._v(_vm._s(h.code) + "- " + _vm._s(h.desc))]
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex justify-start subHeaderSubtitle",
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(h.code) +
                                                      "- " +
                                                      _vm._s(
                                                        _vm.start_and_end(
                                                          h.desc,
                                                          42
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(h.code) +
                                          "-" +
                                          _vm._s(h.desc) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "containerForText",
                        attrs: { id: "idAgNociveDesc" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "mt-0 d-flex justify-start subHeaderTitle",
                          },
                          [_vm._v("Descrição")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-flex justify-start subHeaderSubtitle",
                            staticStyle: { height: "24px" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                h.eventDesc ? h.eventDesc : "Não informado"
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "containerForText",
                        attrs: { id: "idAgNociveInit" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "mt-0 d-flex justify-start subHeaderTitle",
                          },
                          [_vm._v("Inicio de condição")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-flex justify-start subHeaderSubtitle",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                h.initEvent ? h.initEvent : "Não informado"
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "containerForText" }, [
                      _c(
                        "span",
                        { staticClass: "d-flex justify-start subHeaderTitle" },
                        [
                          _vm._v("Limite registrado "),
                          h.consideredLimit
                            ? _c("span", [
                                _vm.isUpperLevel(h)
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "material-symbols-outlined",
                                        attrs: { id: "iconInfoWrong" },
                                      },
                                      [_vm._v(" info ")]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "material-symbols-outlined",
                                        attrs: { id: "iconInfoRight" },
                                      },
                                      [_vm._v("done ")]
                                    ),
                                _vm.isUpperLevel(h)
                                  ? _c(
                                      "span",
                                      { attrs: { id: "iconInfoTextWrong" } },
                                      [
                                        _vm._v(
                                          "Acima da tolerância " +
                                            _vm._s(h.consideredLimit) +
                                            " " +
                                            _vm._s(h.limitType)
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { attrs: { id: "iconInfoTextRight" } },
                                      [
                                        _vm._v(
                                          " Dentro da tolerância " +
                                            _vm._s(h.consideredLimit) +
                                            _vm._s(h.limitType)
                                        ),
                                      ]
                                    ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "d-flex justify-start subHeaderSubtitle",
                          staticStyle: { "max-height": "24px" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              h.registeredLimit
                                ? h.registeredLimit
                                : "Não informado"
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }
            ),
            0
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-flex",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { height: "48px" },
                      attrs: { color: "success", id: "btnClose" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v(" Fechar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }