<template>
  <v-card style="border: 1px solid #e7e7fa !important">
    <div id="colaboradores-container">
      <div class="d-flex justify-center" id="cardGraphFilter">
        <div class="mr-2 mt-4" :disabled="boolForTags">
          <GraphFilter
            :disabled="boolForTags"
            class="pa-4"
            id="graficoColabAposEspecial"
            :loading="loadingAposEspecial"
            :style="'border-radius: 4px !important;'"
            :graph-information="graphAposEspecial"
            :active="activeSpecialRetirement"
            @filterNow="filterbyAposEspecial()"
          />
        </div>
        <div class="ml-2 mt-4" :disabled="boolForTags">
          <GraphFilter
            :disabled="boolForTags"
            class="pa-4"
            id="graficoColabRiscoExpos"
            :loading="loadingRiscoExposi"
            :style="'border-radius: 4px !important;'"
            :graph-information="graphRiscoExposi"
            :active="activeExposed"
            @filterNow="filterByRisc()"
          />
        </div>
      </div>
      <v-card-title :class="boolForTags ? 'mb-2 mt-4 pa-0' : 'mb-4 mt-4 pa-0'">
        <TableSearch
          label="Pesquisar por nome, matrícula ou CPF"
          id="idSearchBarCollab"
          :disabled="tableSearchDisabled"
          v-model="filterForTags.nameCpfMatriculation"
          @input="onInputTableSearch"
        >
        </TableSearch>
        <v-spacer></v-spacer>
        <FilterButton
          :local-filter="localFilter"
          @clean="clean"
          :disabled-or-not="disabledButtonFilter"
          id="filterbtn"
        />

        <ExportButton :is-disabled="isDisabled" @exportar="exportar" id="idExportBtn" />
      </v-card-title>

      <template>
        <div class="text-right pt-0" v-if="boolForTags || filterApplied">
          <v-chip
            id="nociveAgent"
            v-if="filterForTags.nociveAgent != undefined"
            class="mx-2 mb-2 pl-2 pa-1"
            close
            @click:close="clearFilterPosition('nociveAgent')"
            @click="clickChip('nociveAgent')"
            >{{ filterForTags.nociveAgent ? filterForTags.nociveAgent : 0 }}
          </v-chip>

          <v-chip
            id="specialRetirement"
            v-if="filterForTags.specialRetirement != undefined"
            class="mx-2 mb-2 px-2 py-1"
            close
            @click:close="clearFilterPosition('specialRetirement')"
            @click="clickChip('specialRetirement')"
          >
            {{
              filterForTags.specialRetirement === 'true'
                ? 'Aposentadoria especial: Com risco'
                : 'Aposentadoria especial: Sem risco'
            }}
          </v-chip>

          <v-chip
            id="retirementYears"
            v-if="filterForTags.retirementYears != undefined"
            class="mx-2 mb-2 px-2 py-1"
            close
            @click:close="clearFilterPosition('retirementYears')"
            @click="clickChip('retirementYears')"
          >
            {{ filterForTags.retirementYears ? filterForTags.retirementYears : 0 }} anos Alíquota de
            {{ filterForTags.retirementAliquot ? filterForTags.retirementAliquot : 0 }}%
          </v-chip>

          <v-chip
            id="onlyExposedRisks"
            v-if="filterForTags.onlyExposedRisks === true"
            class="mx-4 mb-4 px-2 py-1"
            close
            @click:close="clearFilterPosition('onlyExposedRisks')"
            @click="clickChip('onlyExposedRisks')"
          >
            Risco apresentado
          </v-chip>
        </div>
      </template>
      <div id="scrollDuplicate">
        <div id="dummy"></div>
      </div>

      <v-data-table
        id="tablec"
        class="pb-4"
        :loading="loadingTable"
        :search="search"
        :headers="headers"
        :options.sync="pagination"
        :header-props="{ sortIcon: null }"
        :items="collaborators"
        :page="pagination.page"
        :server-items-length="totalCollaborators"
        :footer-props="{
          disablePagination: companiesList.length > 1 ? false : true,
          itemsPerPageOptions:
            totalCollaborators < 50
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100', disabled: true },
                  { value: 250, text: '250', disabled: true },
                ]
              : totalCollaborators < 100
              ? [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250', disabled: true },
                ]
              : [
                  { value: 50, text: '50' },
                  { value: 100, text: '100' },
                  { value: 250, text: '250' },
                ],
          disableItemsPerPage: collaborators.length === 0,
          itemsPerPageText: `${funcTitle} por página: `,
          expandIcon: 'mdi-dots-horizontal',
        }"
        :no-data-text="noData(companiesList.length === 0, funcTitle)"
      >
        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} {{ funcTitle }}
        </template>
        <template slot="body" v-if="loadingTable">
          <tr>
            <td v-for="loadItem in headers">
              <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
            </td>
          </tr>
        </template>
        <template v-slot:item.socialName="{ item }"> {{ item.socialName }} </template>
        <template v-slot:item.name="{ item }">
          {{ start_and_end(item.name, 32).toUpperCase() }}
        </template>
        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj | cnpj }}
        </template>
        <template v-slot:item.cpf="{ item }">
          {{ item.cpf }}
        </template>
        <template v-slot:item.cpfMask="{ item }" style="display: none"> </template>
        <template v-slot:item.competenceIn="{ item }">
          {{ item.competenceIn | date({ mask: 'MM/YYYY', showEmpty: true }) }}
        </template>
        <template v-slot:item.categoryType="{ item }">
          {{ item.categoryType }}
        </template>
        <template v-slot:item.categoryCode="{ item }">
          {{ item.categoryCode }}
        </template>
        <template v-slot:item.categoryInfo="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" style="display: inline-block">
                <span> {{ start_and_end(item.categoryInfo, 65) }}</span>
              </span>
            </template>
            <span> {{ item.categoryInfo }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.nociveAgents="{ item }">
          <span style="display: inline-block" v-if="hasAgents(item.nociveAgents)">
            <span id="iconInfo" class="material-symbols-outlined"> info </span>
            <span id="iconInfoText"> Há exposições</span>
          </span>
          <span v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" style="display: inline-block">
                  <span id="iconDone" class="material-symbols-outlined"> done </span>
                  <span>
                    {{
                      start_and_end(
                        'Ausência de agente nocivo ou de atividades previstas no Anexo IV do Decreto 3.048/1999',
                        42,
                      )
                    }}</span
                  >
                </span>
              </template>
              <span>Ausência de agente nocivo ou de atividades previstas no Anexo IV do Decreto 3.048/1999 </span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:item.specialRetirement="{ item }">
          <span v-if="item.specialRetirement">Sim</span>
          <span v-else>Não</span>
        </template>
        <template v-slot:item.retirementAliquot="{ item }">
          {{ item.specialRetirement === false ? 'Ausente' : item.retirementAliquot }}
        </template>
        <template v-slot:item.lastUpdate="{ item }">
          {{ item.lastUpdate | dateAsTime }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :id="'btn-action-' + item.id"
                class="btn-action material-symbols-outlined"
                icon
                @click="showModal(item)"
                v-if="hasAgents(item.nociveAgents)"
              >
                <span class="material-symbols-outlined" id="iconDescription"> description </span>
              </v-btn>

              <span v-else> </span>
            </template>
            <span>Ver agentes nocivos </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <ModalForNociveAgents ref="modalForNociveAgents" :colaborator="colabForModal" />
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import GraphFilter from './GraphFilter.vue';

import FilterButton from './FilterForTable.vue';
import ExportButton from './ExportButton.vue';
import { eventBus } from '@/main.js';
import ModalForNociveAgents from './ModalForNociveAgents.vue';

export default {
  name: 'TableColaboradores',

  components: {
    ModalForNociveAgents,
    GraphFilter,
    FilterButton,
    ExportButton,
    TableSearch: () => import('../../commons/TableSearch.vue'),
  },
  updated() {
    this.customize();
    this.changeIcon();
  },

  mounted() {
    this.changeIcon();
    this.updateScrollers();
    this.prepareListeners();
    this.disableCollaboratorsSearch();
    window.addEventListener('resize', this.updateScrollers);

    const linkScroller = (a, b) => {
      a.addEventListener('scroll', (e) => {
        b.scrollLeft = e.target.scrollLeft;
      });
    };
    const _a = this.$el.querySelector('#scrollDuplicate');
    const _b = this.$el.querySelector('.v-data-table__wrapper');
    linkScroller(_a, _b);
    linkScroller(_b, _a);
  },

  watch: {
    collaborators(value) {
      if (value.length === 0) {
        this.search = '';
        this.changeColors(true);
      } else {
        this.changeColors(false);
      }
    },
  },
  data() {
    return {
      colabForModal: {},

      funcTitle: process.env.VUE_APP_TAGFUNC,
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
      tableSearchLoading: true,
      search: '',
      localFilter: { nociveAgent: '', specialRetirement: '', retirementAliquot: '', onlyExposedRisks: false },
      totalColaboradores: 0,
      headers: [
        { text: 'Empresa', value: 'socialName', width: '352px', sortable: true },
        { text: 'CNPJ', value: 'cnpj', width: '184px', sortable: true },
        { text: 'Matrícula', value: 'matriculation', width: '93px', sortable: true },
        { text: 'Nome', value: 'name', width: '208px', sortable: true },
        { text: '', value: 'cpfMask', width: '0px', sortable: true },
        { text: 'CPF', value: 'cpf', width: '148px', sortable: true },
        { text: 'Competência', value: 'competenceIn', width: '118px', sortable: true },
        { text: 'Tipo da categoria', value: 'categoryType', width: '220px', sortable: true },
        { text: 'Cód. Categoria', value: 'categoryCode', width: '145px', sortable: true },
        { text: 'Categoria', value: 'categoryInfo', width: '380px', sortable: true },
        { text: 'Agente nocivo', value: 'nociveAgents', width: ' 310px', sortable: false },
        { text: 'Aposentadoria especial', value: 'specialRetirement', width: '183px', sortable: true },
        { text: 'Contribuição / Alíquota', value: 'retirementAliquot', width: '181px', sortable: true },
        { text: 'Atualização', value: 'lastUpdate', width: '185px', sortable: true },
        { text: 'Ações', value: 'action', width: '185px', sortable: false },
      ],
      customStyleSkeleton:
        'text-align: start;color: #e7e7fa;font-family: "Roboto";font-weight: 700;font-size: 28px;letter-spacing: 1%;height: 51px;flex-direction: row;align-items: flex-start; border-left: 2px solid #ffffff',
    };
  },
  created() {},

  computed: {
    ...mapGetters({
      boolForTags: 'wss/getBoolForTags',
      companiesList: 'wss/companiesList',
      collaborators: 'wss/getCollaborators',
      exposexCollaborators: 'wss/getCountExposedCollaborators',
      retirementRisks: 'wss/getRetirementRisks',
      activeExposed: 'wss/getActiveExposed',
      activeSpecialRetirement: 'wss/getActiveSpecialRetirement',
      loading: 'wss/getAllDataLoaded',
      loadingTable: 'wss/getLoadingTable',
      loadingRiscoExposi: 'wss/getAllDataLoaded',
      loadingAposEspecial: 'wss/getAllDataLoaded',
      paginationFilter: 'wss/getPaginationColab',
      totalCollaborators: 'wss/getTotalCollaborators',
      filterCompetency: 'wss/getCountNociveAgentFilter',
      filterForTags: 'wss/getCountNociveAgentFilter',
      selectedLength: 'wss/getBoolForFilterButton',
      scheduleReport: 'wss/getScheduleReport',
      filterApplied: 'wss/getFilterApply',
      // nameCpfMatriculation: 'wss/getCollaboratorSearch',
    }),
    isDisabled() {
      if (this.collaborators.length < 1) {
        return true;
      } else if (this.companiesList.length < 1) {
        return true;
      } else if (this.filterCompetency.competencyIn === '') {
        return true;
      } else if (this.loading) {
        return true;
      } else {
        return false;
      }
    },
    disabledButtonFilter() {
      if (this.collaborators.length < 1) {
        return true;
      } else if (this.companiesList.length < 1) {
        return true;
      } else if (this.filterCompetency.competencyIn === '') {
        return true;
      } else if (this.loading) {
        return true;
      } else if (this.activeSpecialRetirement) {
        return true;
      } else if (this.activeExposed) {
        return true;
      } else {
        return false;
      }
    },

    graphRiscoExposi() {
      let exposed = {
        title: `${this.funcTitle} com risco de exposição`,
        qtdColab: [this.exposexCollaborators.exposed, this.exposexCollaborators.total],
        totalColab: this.exposexCollaborators.total,
        percent: this.exposexCollaborators.percentage,
      };
      return exposed;
    },
    graphAposEspecial() {
      let retirementRisks = {
        title: `${this.funcTitle} com aposentadoria especial`,
        qtdColab: [this.retirementRisks.specialRetirements, this.retirementRisks.total],
        totalColab: this.retirementRisks.total,
        percent: this.retirementRisks.percentage,
      };
      return retirementRisks;
    },
    pagination: {
      get: function () {
        return this.paginationFilter;
      },
      set: function (value) {
        this.setPaginationColab(value);
      },
    },
    tableSearchDisabled() {
      return this.loading || this.tableSearchLoading;
    },
  },

  methods: {
    ...mapActions('wss', [
      'getCompanies',
      'collaboratorRetirementRisks',
      'specialRetirementFromGraph',
      'exposedRisksFromGraph',
      'setIsFromFilterExposed',
      'setIsFromFilterSpecial',
      'setPaginationColab',
      'cleanFilterPosition',
      'searchCollaborator',
      'exportCollaborators',
    ]),

    noData(companies, funcTitle) {
      let text;
      if (companies) {
        text = `Não há registros de ` + funcTitle + ` a serem exibidos.`;
        this.applyCss(2);
      } else {
        text =
          `Verifique se há registros de ` +
          funcTitle +
          ` a serem exibidos. Selecione uma competência e aplique o filtro.`;
        this.applyCss(1);
      }

      return text;
    },
    hasAgents(item) {
      if (item.length > 0)
        if (item.length === 1 && item[0].desc.includes('Ausência de agente')) {
          return false;
        } else {
          return true;
        }
      else {
        return false;
      }
    },
    showModal(item) {
      this.colabForModal = item;
      this.$refs.modalForNociveAgents.$emit('open');
    },
    applyCss(type) {
      if (document.querySelector('.v-data-table tr.v-data-table__empty-wrapper td') != null) {
        let td = document.querySelector('.v-data-table tr.v-data-table__empty-wrapper td');
        let style;
        let text = td.textContent;

        if (type == 1) {
          style = 280 + 'px';
        } else if (type == 2) {
          style = 450 + 'px';
        }
        td.style['text-align'] = 'left';
        td.style['padding-left'] = style;
      }
    },
    changeIcon2() {
      const listItem = document.getElementsByClassName('material-icons-outlined');

      let selected = '';
      if (listItem[4] != undefined && listItem[4].innerHTML === 'expand_more') {
        //<!-- const itemSelected = listItem[4]; -->
        selected = listItem[4];
        selected.style.color = 'var(--v-primary-base)';
        selected.style.fontSize = '24px';
        selected.style.fontWeight = 'regular';
        console.log('if2');
      } else {
        const listItemNew = document.getElementsByClassName('v-input__icon v-input__icon--append')[1];
        const newITem = document.createElement('span');
        newITem.setAttribute('class', 'material-icons-outlined');
        newITem.setAttribute('id', 'material-icons-outlined');
        newITem.innerText = 'expand_more';

        newITem.style.color = '#8686A2';
        newITem.style.fontSize = '24px';
        newITem.style.fontWeight = 'regular';
        listItemNew.parentNode.replaceChild(newITem, listItemNew);
      }
    },
    changeIcon() {
      const isDisabledOrNot = document.getElementsByClassName(
        'v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--is-disabled theme--light v-text-field v-text-field--is-booted v-select',
      );

      if (isDisabledOrNot[0]) {
        const listItem = document.getElementsByClassName(
          'v-icon notranslate v-icon--disabled mdi mdi-menu-down theme--light',
        );
        const itemSelected = listItem[0];

        const newITem = document.createElement('span');
        newITem.setAttribute('class', 'material-icons-outlined');
        newITem.setAttribute('id', 'material-icons-outlined');
        newITem.innerText = 'expand_more';

        newITem.style.color = 'var(--v-primary-base)';
        newITem.style.fontSize = '24px';
        newITem.style.fontWeight = 'regular';
        if (listItem[0]) {
          let iconSelect = document.getElementById('material-icons-outlined');
          if (iconSelect == null) {
            newITem.style.color = '#8686A2';
          }
          listItem[0].parentNode.replaceChild(newITem, itemSelected);
        }
      } else {
        const listItem = document.getElementsByClassName('material-icons-outlined');

        let selected = '';
        if (listItem[4] != undefined && listItem[4].innerHTML === 'expand_more') {
          //<!-- const itemSelected = listItem[4]; -->
          selected = listItem[4];
          selected.style.color = 'var(--v-primary-base)';
          selected.style.fontSize = '24px';
          selected.style.fontWeight = 'regular';
        }
      }
    },

    async exportar() {
      await this.exportCollaborators();
      if (this.scheduleReport) {
        this.showToast(
          'Recebemos seu pedido com sucesso! Nos menus Agnes em: Documentos e Download. Assim que concluído, o arquivo estará disponível.',
          'success',
          78,
        );
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
    async prepareListeners(e) {
      this.$root.$on('disableCollaboratorsSearch', () => {
        this.disableCollaboratorsSearch();
      });
      this.$root.$on('enableCollaboratorsSearch', () => {
        this.enableCollaboratorsSearch();
      });
    },
    updateScrollers() {
      const dummy = this.$el.querySelector('#dummy');
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      dummy.style.width = _b.scrollWidth + 'px';
    },
    customize() {
      let tableLines = document.querySelectorAll('#colaboradores-container tbody tr');
      if (tableLines && tableLines.length > 1) {
        for (let i = 0; i < tableLines.length; i++) {
          if (i % 2) {
            tableLines[i].style.backgroundColor = '#F7F7FD';
          }
        }
      }
    },
    start_and_end(str = '', lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
    clickChip(value) {
      let buttonClosed = document.querySelector('#' + value + ' span button');
      buttonClosed.classList.remove('v-icon');
      buttonClosed.classList.add('v-icon-clicked');
    },
    clearFilterPosition(value) {
      if (value != 'retirementYears' && value != 'onlyExposedRisks') {
        this.localFilter[value] = '';
      } else if (value === 'onlyExposedRisks') {
        this.localFilter[value] = false;
      } else {
        this.localFilter.retirementYears = '';
        this.localFilter.retirementAliquot = '';
      }

      this.cleanFilterPosition(value);
    },
    clean(value) {
      this.localFilter = value;
    },
    disableCollaboratorsSearch() {
      this.tableSearchLoading = true;
    },
    enableCollaboratorsSearch() {
      this.tableSearchLoading = false;
    },
    filterByRisc() {
      let localFilterForChange = {
        nociveAgent: '',
        specialRetirement: '',
        retirementAliquot: '',
        onlyExposedRisks: false,
      };
      this.clean(localFilterForChange);
      this.setIsFromFilterExposed(!this.activeExposed);
      // this.setIsFromFilterSpecial(false);
      // this.exposedRisksFromGraph();
    },
    filterbyAposEspecial() {
      let localFilterForChange = {
        nociveAgent: '',
        specialRetirement: '',
        retirementAliquot: '',
        onlyExposedRisks: false,
      };
      this.clean(localFilterForChange);
      this.setIsFromFilterSpecial(!this.activeSpecialRetirement);
      // this.setIsFromFilterExposed(false);
      // this.specialRetirementFromGraph();
    },
    changeColors(revertDefault) {
      let iconSelect = document.getElementById('material-icons-outlined');
      let commaSelection = document.querySelector('.v-select__selection.v-select__selection--comma');

      if (!revertDefault) {
        if (iconSelect != null && commaSelection != null) {
          iconSelect.style.color = 'var(--v-primary-base)';
          commaSelection.setAttribute('style', 'color: var(--v-primary-base) !important;');
        }
      } else if (revertDefault) {
        if (iconSelect != null && commaSelection != null) {
          iconSelect.style.color = '#8686a2';
          commaSelection.setAttribute('style', 'color: #8686a2 !important;');
        }
      }
    },
    onInputTableSearch(val) {
      this.searchCollaborator(val);
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
::v-deep #scrollDuplicate,
::v-deep .v-data-table__wrapper {
  overflow-x: auto !important;
}

::v-deep .v-data-table__wrapper table {
  max-height: 330px !important;
  width: 200vw !important;
}

::v-deep #scrollDuplicate > div {
  height: 1px !important;
}
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}
::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}
::v-deep .v-data-table__wrapper table thead.v-data-table-header tr th.text-start {
  border-bottom: none;
}
::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
::v-deep .v-data-table tr td {
  border-bottom: 0px !important;
}
::v-deep .v-data-table tr td.text-start {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #52526b !important;
}
::v-deep .v-data-table tr.v-data-table__empty-wrapper {
  background-color: #f7f7fd !important;
}

::v-deep .v-data-footer__select {
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}

::v-deep .v-data-footer__select .v-select {
  margin: 16px 64px 16px 8px !important;
}
::v-deep .v-data-footer__pagination {
  margin: 0px 64px 0px 0px;
  font-size: 16px !important;
  color: #52526b !important;
  font-weight: normal !important;
}
:v-deep v-btn .v-btn--disabled .v-btn--icon .v-btn--round .v-btn--text .theme--light .v-size--default {
  margin-right: 16px !important;
}

::v-deep .icon-action {
  margin: 0 !important;
  padding: 0 !important;
}

::v-deep .v-data-table tr td.text-start {
  cursor: default !important;
}

i.mdi-information {
  height: 16px;
  width: 16px;
  color: #c73c44;
}
::v-deep .v-chip {
  color: var(--v-primary-base);
  font-size: 14px !important;
  max-height: 26px !important;
  font-weight: 'regular' !important;
  background-color: #f7f7fd !important;
  border: 1px solid #e7e7fa !important;
  padding: 1px 1px 1px 8px !important;
}
::v-deep .v-chip .v-chip__content {
  color: var(--v-primary-base);
  font-size: 14px !important;
  font-weight: 'regular' !important;
  background-color: #f7f7fd !important;
}
::v-deep .v-chip .v-icon {
  color: white !important;
  width: 24px !important;
  height: 24px !important;
  line-height: 26px !important;
  font-size: 16px !important;
  background-color: var(--v-primary-base) !important;
  border-radius: 100% !important;
  border: 4px solid white !important;
  margin-left: 4px !important;
  margin-right: 0px !important;
}

::v-deep .v-icon-clicked {
  color: var(--v-primary-base);
  font-size: 16px !important;
  background-color: #f7f7fd !important;
  border-radius: 100% !important;
  border-color: #e7e7fa !important;
}
::v-deep .v-chip:hover .v-icon {
  color: #e3ffee !important;
  font-size: 16x !important;
  line-height: 24px !important;
  background-color: var(--v-primary-base) !important;
  border-radius: 100% !important;
  border: 4px solid #e3ffee !important;
  box-shadow: none !important;
  opacity: 1;
}

i.mdi-check {
  height: 16px;
  width: 16px;
  color: #3cc79d;
}
::v-deep .v-input__slot::before {
  border: none !important;
}

::v-deep .v-data-table {
  overflow-x: auto !important;
}
::v-deep .v-data-footer {
  padding-left: 16px;
  padding-right: 16px;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-left: 0px !important;
  margin-right: 0;
  padding: 0;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: #e7e7fa;
  color: var(--v-primary-base);
  height: 24px !important;
  width: 24px !important;
  border-radius: 24px !important;
  margin-right: 8px !important;
  padding: 0;
}

#iconInfo {
  font-variation-settings: 'FILL' 1;
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  vertical-align: middle !important;
  color: #c73c44;
}
#iconInfoText {
  vertical-align: middle !important;
  line-height: 24px !important;
  font-size: 16px !important;
}
#iconDone.material-symbols-outlined {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  vertical-align: middle !important;
  color: #3cc79d;
}

#iconDescription.material-symbols-outlined {
  width: 24px !important;
  height: 24px !important;
  font-size: 16px !important;
  border-radius: 100% !important;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--v-primary-base);
}
#iconDescription.material-symbols-outlined:hover {
  color: #442d96;
  background-color: #e3ffee;
}
#iconDescription.material-symbols-outlined:active {
  color: white;
  background-color: #442d96;
}

#iconDoneText {
  vertical-align: middle !important;
  line-height: 24px !important;
  font-size: 16px !important;
}
.loaderCss {
  width: 100%;
  margin-top: 12px;
  margin-left: 15px;
  margin-bottom: 12px;
  height: 15px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 200px;
  animation: shine 2s ease infinite;
}
::v-deep .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot::after {
  transform: scaleX(0) !important;
}
::v-deep
  .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select.v-input__control {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep .v-input__slot::before {
  background: #ffffff !important;
  color: var(--v-primary-base);
}
::v-deep
  .v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-select.v-input__control:hover {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}
::v-deep .v-data-footer .v-input__slot {
  background: #e3ffee !important;
  color: var(--v-primary-base);
}

::v-deep .v-data-footer .v-data-footer__select .v-select {
  background: #ffffff !important;
  color: var(--v-primary-base);
  margin-top: 16px;
  margin-bottom: 0px;
}
::v-deep .v-select__selection--comma {
  overflow: visible !important;
}
/* ::v-deep #tablec .v-input--is-disabled .v-input__control .v-input__slot {
  background-color: #e7e7fa !important;
  color: var(--v-primary-base);
  border: none;
} */
::v-deep .v-select__selection.v-select__selection--comma {
  overflow: visible !important;
}
::v-deep #tablec .v-select__selection {
  color: #8686a2;
}
::v-deep #tablec .v-input--is-label-active:not(.v-input--is-disabled) .v-select__selection {
  color: var(--v-primary-base);
}
::v-deep #tablec .v-input--is-label-active:not(.v-input--is-disabled) .v-select__selection:hover {
  background-color: #e3ffee !important;
}
::v-deep .v-data-footer__icons-after .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-after .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn:active {
  background-color: #2a1c4f !important;
  color: white !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn {
  background-color: white !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

::v-deep .v-data-footer__icons-before:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  transition: none !important;
  opacity: 1 !important;
  padding: 0 !important;
}

::v-deep .v-select__slot .v-input__append-inner {
  padding-top: 7px;
}
::v-deep .v-select__slot .v-select__selections .v-select__selection {
  font-family: Roboto !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #8686a2 !important;
}

::v-deep .v-input {
  align-items: center !important;
}
::v-deep .v-data-footer .v-select__slot {
  background-color: #e7e7fa;
  height: 48px;
  width: 83px;
  border-radius: 4px;
  border: 1px solid #e7e7fa;
  padding-left: 15px;
  padding-right: 10px;
}

::v-deep .v-data-footer__icons-after:hover .v-btn:not(.v-btn--disabled) {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  opacity: 1 !important;
  transition: none !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-before .v-btn--disabled {
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
  padding: 0 !important;
}
::v-deep .v-data-footer__icons-after .v-btn--disabled {
  background-color: #e7e7fa !important;
  border-radius: 100% !important;
  padding: 0 !important;
}
@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

::v-deep .ag-search-input .v-input__control .v-input__slot .v-text-field__slot .v-label {
  font-weight: 400 !important;
}
</style>
