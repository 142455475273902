<template>
  <v-dialog v-model="show" persistent>
    <v-card class="cardAgents">
      <v-card-title class="titleStyle">
        <v-col cols="11" class="ml-n4"> {{ colaboratorCopy.name }}</v-col>

        <v-col cols="1" class="ml-4">
          <v-btn id="btnCloseX" icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <div id="cardScroll2">
        <div v-for="h in filterItem(colaboratorCopy.nociveAgents)" v-bind:key="h.id" class="cardForEachAgent">
          <div class="containerForText" id="idAgNociveTitle">
            <span class="d-flex justify-start subHeaderTitle">Agente nocivo </span>
            <span class="d-flex justify-start subHeaderSubtitle" v-if="h.desc.lenght < 42"
              >{{ h.code }}- {{ h.desc }}</span
            >
            <span class="d-flex justify-start subHeaderSubtitle" v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> {{ h.code }}- {{ start_and_end(h.desc, 42) }} </span>
                </template>
                <span>{{ h.code }}-{{ h.desc }} </span>
              </v-tooltip>
            </span>
          </div>
          <div class="containerForText" id="idAgNociveDesc">
            <span class="mt-0 d-flex justify-start subHeaderTitle">Descrição</span>
            <span class="d-flex justify-start subHeaderSubtitle" style="height: 24px">{{
              h.eventDesc ? h.eventDesc : 'Não informado'
            }}</span>
          </div>
          <div class="containerForText" id="idAgNociveInit">
            <span class="mt-0 d-flex justify-start subHeaderTitle">Inicio de condição</span>
            <span class="d-flex justify-start subHeaderSubtitle">{{
              h.initEvent ? h.initEvent : 'Não informado'
            }}</span>
          </div>
          <div class="containerForText">
            <span class="d-flex justify-start subHeaderTitle"
              >Limite registrado
              <span v-if="h.consideredLimit">
                <span id="iconInfoWrong" class="material-symbols-outlined" v-if="isUpperLevel(h)"> info </span>
                <span id="iconInfoRight" class="material-symbols-outlined" v-else>done </span>
                <span id="iconInfoTextWrong" v-if="isUpperLevel(h)"
                  >Acima da tolerância {{ h.consideredLimit }} {{ h.limitType }}</span
                >
                <span id="iconInfoTextRight" v-else>
                  Dentro da tolerância {{ h.consideredLimit }}{{ h.limitType }}</span
                >
              </span></span
            >

            <span class="d-flex justify-start subHeaderSubtitle" style="max-height: 24px">{{
              h.registeredLimit ? h.registeredLimit : 'Não informado'
            }}</span>
          </div>
        </div>
      </div>

      <v-card-actions>
        <v-flex class="text-right">
          <v-btn color="success" @click="show = false" style="height: 48px" id="btnClose"> Fechar </v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { capitalize } from 'lodash';

export default {
  name: 'ModalHistoryCrf',
  props: {
    colaborator: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },
  computed: {
    colaboratorCopy() {
      return this.colaborator;
    },
  },
  created() {
    this.init();
  },

  methods: {
    isUpperLevel(object) {
      let registered = Number(object.registeredLimit);
      let considered = Number(object.consideredLimit);
      if (registered > considered) {
        return true;
      } else {
        return false;
      }
    },
    capitalizeName(name) {
      let nameToLower = name.toLowerCase();
      const words = nameToLower.split(' ');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words;
    },
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },
    filterItem(list) {
      let newList = list.filter((item) => !item.desc.includes('Ausência de agente'));
      return newList;
    },
    start_and_end(str = '', lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
    open() {
      this.show = true;
    },

    close() {
      this.show = false;
    },
  },
};
</script>
<style>
#cardScroll2 {
  width: 100% !important;
  height: 554px;
  background-color: #e7e7fa;
  padding: 16px !important;
  flex-grow: 1;
  overflow: auto;
}
#iconInfoWrong {
  font-variation-settings: 'FILL' 1;
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
  vertical-align: middle !important;
  color: #c73c44;
}
#iconInfoRight {
  font-variation-settings: 'FILL' 1;
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
  vertical-align: middle !important;
  color: #288569;
}
#iconInfoTextWrong {
  vertical-align: middle !important;
  color: var(--AG-Capital-Feedback-Color-Error-Medium, #962d33);
  /* Fontes/Regular/XXXS */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; /* 15.96px */
  letter-spacing: 0.12px;
}
#iconInfoTextRight {
  vertical-align: middle !important;
  color: var(--AG-Capital-Feedback-Color-Error-Medium, #288569);
  /* Fontes/Regular/XXXS */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; /* 15.96px */
  letter-spacing: 0.12px;
}

#export-btn {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 2px solid var(--v-primary-base) !important;
  background-color: #fff !important;
  color: var(--v-primary-base);
}
#export-btn :hover {
  background-color: #e3ffee !important;
  width: 150%;
  height: 100%;
  border-radius: 3px;
}
#btnClose :hover {
  background-color: #e3ffee !important;
  border-radius: 3px;
  width: 170% !important;
  height: 100% !important;
  color: var(--v-primary-base);
}

.titleStyle {
  max-height: 64px !important;
  flex-wrap: nowrap !important;
  color: var(--AG-Capital-Neutral-Color-High-Dark, #182026);
  /* Fontes/Bold/SM */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: 0.2px;
  padding-left: 16px;
  padding-right: 16px;
  flex: 1 0 0;
}
#idAgNociveTitle {
  padding-bottom: 16px;
}
#idAgNociveDesc {
  padding-bottom: 16px;
}
#idAgNociveInit {
  padding-bottom: 16px;
}
.cardAgents {
  background-color: white;
  width: 560px;
  height: 698px;
  padding: 0px !important;
}
.v-dialog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 550px;
  height: 698px;
}
.cardForEachAgent {
  width: 100%;
  height: 252px;
  background-color: white !important;
  padding: 16px;
}
.cardForEachAgent:not(:last-child) {
  margin-bottom: 16px !important;
}
.subHeaderTitle {
  max-height: 19px;
  color: var(--AG-Capital-Neutral-Color-High-Light, #8686a2);
  /* Fontes/Regular/XXS */
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;

  letter-spacing: 0.14px;
}
.subHeaderSubtitle {
  align-self: stretch;
  max-height: 24px;

  text-overflow: ellipsis;
  font-size: 16px !important;
  color: var(--AG-Capital-Neutral-Color-High-Medium, #52526b);
  /* Fontes/Bold/XS */
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
}
.containerForText {
  height: 43px;
  padding: 0px;
  margin-bottom: 16px;
  width: 100%;
}
.span {
  padding: 0px;
}
</style>
