var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticStyle: { border: "1px solid #e7e7fa !important" } },
    [
      _c(
        "div",
        { attrs: { id: "nocive-agents-container" } },
        [
          _c(
            "v-card-title",
            { staticClass: "pl-0 pr-0 pt-0" },
            [
              _c("TableSearch", {
                attrs: {
                  id: "searchBardAgNociv",
                  label: "Pesquisar por código ou agente nocivo",
                  disabled: _vm.tableSearchDisabled,
                },
                on: { input: _vm.onInputTableAgentesNocivosSearch },
                model: {
                  value: _vm.filterCompetency.nociveAgentCodeDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.filterCompetency, "nociveAgentCodeDesc", $$v)
                  },
                  expression: "filterCompetency.nociveAgentCodeDesc",
                },
              }),
              _c("v-spacer"),
              _c("ExportButton", {
                attrs: { id: "exportBtn", "is-disabled": _vm.isDisabled },
                on: { exportar: _vm.exportar },
              }),
            ],
            1
          ),
          _c("div", { attrs: { id: "scrollDuplicate" } }, [
            _c("div", { attrs: { id: "dummy" } }),
          ]),
          _c(
            "v-data-table",
            {
              staticClass: "pb-4",
              attrs: {
                loading: _vm.loadingTable,
                search: _vm.search,
                headers: _vm.headers,
                options: _vm.pagination,
                "header-props": { sortIcon: null },
                items: _vm.nociveAgents,
                page: _vm.pagination.page,
                "server-items-length": _vm.totalNociveAgents,
                "footer-props": {
                  disablePagination:
                    _vm.companiesList.length > 1 ? false : true,
                  itemsPerPageOptions:
                    _vm.totalNociveAgents < 50
                      ? [
                          { value: 50, text: "50" },
                          { value: 100, text: "100", disabled: true },
                          { value: 250, text: "250", disabled: true },
                        ]
                      : _vm.totalNociveAgents < 100
                      ? [
                          { value: 50, text: "50" },
                          { value: 100, text: "100" },
                          { value: 250, text: "250", disabled: true },
                        ]
                      : [
                          { value: 50, text: "50" },
                          { value: 100, text: "100" },
                          { value: 250, text: "250" },
                        ],
                  disableItemsPerPage: _vm.nociveAgents.length === 0,
                  itemsPerPageText: "Agentes nocivos por página: ",
                  expandIcon: "mdi-dots-horizontal",
                },
              },
              on: {
                "update:options": function ($event) {
                  _vm.pagination = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer.page-text",
                  fn: function (items) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(items.pageStart) +
                          " - " +
                          _vm._s(items.pageStop) +
                          " de " +
                          _vm._s(items.itemsLength) +
                          " agentes nocivos "
                      ),
                    ]
                  },
                },
                {
                  key: "item.socialName",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.socialName) + " ")]
                  },
                },
                {
                  key: "item.cnpj",
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " "),
                    ]
                  },
                },
                {
                  key: "item.code",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.code) + " ")]
                  },
                },
                {
                  key: "header.collaborators",
                  fn: function ({ header }) {
                    return [
                      _c("span", { staticClass: "mr-2 align-center" }, [
                        _vm._v(" Trabalhadores"),
                      ]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "primary" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("mdi-information")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Número de " +
                                _vm._s(_vm.funcTitle) +
                                " Expostos ao Agente Nocivo"
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "item.consideredLimit",
                  fn: function ({ item }) {
                    return [
                      _c("div", { staticClass: "d-flex flex-row" }, [
                        item.consideredLimit ===
                        "Não se aplica a agentes nocivos qualitativos."
                          ? _c(
                              "span",
                              { staticClass: "icon-nocive-agents" },
                              [_c("v-icon", [_vm._v("error_outline")])],
                              1
                            )
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(item.consideredLimit))]),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.desc",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b({}, "span", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.start_and_end(item.desc, 32)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v(_vm._s(item.desc))])]
                      ),
                    ]
                  },
                },
                {
                  key: "item.lastUpdate",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dateAsTime")(item.lastUpdate)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.action",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-action",
                          attrs: {
                            id: "btn-action-" + item.id,
                            icon: "",
                            disabled: _vm.nociveAgents.length === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showModal(item)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-file-document-outline")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.loadingTable
                ? _c("template", { slot: "body" }, [
                    _c(
                      "tr",
                      _vm._l(_vm.headers, function (loadItem) {
                        return _c(
                          "td",
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "loaderCss",
                              attrs: { "max-width": loadItem.width },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("ModalAgentesNocivos", {
        ref: "modalAgentesNocivos",
        attrs: { item: _vm.itemAgentNocive },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }