var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticStyle: { border: "1px solid #e7e7fa !important" } },
    [
      _c(
        "div",
        { attrs: { id: "colaboradores-container" } },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-center",
              attrs: { id: "cardGraphFilter" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mr-2 mt-4",
                  attrs: { disabled: _vm.boolForTags },
                },
                [
                  _c("GraphFilter", {
                    staticClass: "pa-4",
                    style: "border-radius: 4px !important;",
                    attrs: {
                      disabled: _vm.boolForTags,
                      id: "graficoColabAposEspecial",
                      loading: _vm.loadingAposEspecial,
                      "graph-information": _vm.graphAposEspecial,
                      active: _vm.activeSpecialRetirement,
                    },
                    on: {
                      filterNow: function ($event) {
                        return _vm.filterbyAposEspecial()
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "ml-2 mt-4",
                  attrs: { disabled: _vm.boolForTags },
                },
                [
                  _c("GraphFilter", {
                    staticClass: "pa-4",
                    style: "border-radius: 4px !important;",
                    attrs: {
                      disabled: _vm.boolForTags,
                      id: "graficoColabRiscoExpos",
                      loading: _vm.loadingRiscoExposi,
                      "graph-information": _vm.graphRiscoExposi,
                      active: _vm.activeExposed,
                    },
                    on: {
                      filterNow: function ($event) {
                        return _vm.filterByRisc()
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-card-title",
            { class: _vm.boolForTags ? "mb-2 mt-4 pa-0" : "mb-4 mt-4 pa-0" },
            [
              _c("TableSearch", {
                attrs: {
                  label: "Pesquisar por nome, matrícula ou CPF",
                  id: "idSearchBarCollab",
                  disabled: _vm.tableSearchDisabled,
                },
                on: { input: _vm.onInputTableSearch },
                model: {
                  value: _vm.filterForTags.nameCpfMatriculation,
                  callback: function ($$v) {
                    _vm.$set(_vm.filterForTags, "nameCpfMatriculation", $$v)
                  },
                  expression: "filterForTags.nameCpfMatriculation",
                },
              }),
              _c("v-spacer"),
              _c("FilterButton", {
                attrs: {
                  "local-filter": _vm.localFilter,
                  "disabled-or-not": _vm.disabledButtonFilter,
                  id: "filterbtn",
                },
                on: { clean: _vm.clean },
              }),
              _c("ExportButton", {
                attrs: { "is-disabled": _vm.isDisabled, id: "idExportBtn" },
                on: { exportar: _vm.exportar },
              }),
            ],
            1
          ),
          [
            _vm.boolForTags || _vm.filterApplied
              ? _c(
                  "div",
                  { staticClass: "text-right pt-0" },
                  [
                    _vm.filterForTags.nociveAgent != undefined
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "mx-2 mb-2 pl-2 pa-1",
                            attrs: { id: "nociveAgent", close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.clearFilterPosition("nociveAgent")
                              },
                              click: function ($event) {
                                return _vm.clickChip("nociveAgent")
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.filterForTags.nociveAgent
                                  ? _vm.filterForTags.nociveAgent
                                  : 0
                              ) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.filterForTags.specialRetirement != undefined
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "mx-2 mb-2 px-2 py-1",
                            attrs: { id: "specialRetirement", close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.clearFilterPosition(
                                  "specialRetirement"
                                )
                              },
                              click: function ($event) {
                                return _vm.clickChip("specialRetirement")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.filterForTags.specialRetirement === "true"
                                    ? "Aposentadoria especial: Com risco"
                                    : "Aposentadoria especial: Sem risco"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.filterForTags.retirementYears != undefined
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "mx-2 mb-2 px-2 py-1",
                            attrs: { id: "retirementYears", close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.clearFilterPosition(
                                  "retirementYears"
                                )
                              },
                              click: function ($event) {
                                return _vm.clickChip("retirementYears")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.filterForTags.retirementYears
                                    ? _vm.filterForTags.retirementYears
                                    : 0
                                ) +
                                " anos Alíquota de " +
                                _vm._s(
                                  _vm.filterForTags.retirementAliquot
                                    ? _vm.filterForTags.retirementAliquot
                                    : 0
                                ) +
                                "% "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.filterForTags.onlyExposedRisks === true
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "mx-4 mb-4 px-2 py-1",
                            attrs: { id: "onlyExposedRisks", close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.clearFilterPosition(
                                  "onlyExposedRisks"
                                )
                              },
                              click: function ($event) {
                                return _vm.clickChip("onlyExposedRisks")
                              },
                            },
                          },
                          [_vm._v(" Risco apresentado ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          _c("div", { attrs: { id: "scrollDuplicate" } }, [
            _c("div", { attrs: { id: "dummy" } }),
          ]),
          _c(
            "v-data-table",
            {
              staticClass: "pb-4",
              attrs: {
                id: "tablec",
                loading: _vm.loadingTable,
                search: _vm.search,
                headers: _vm.headers,
                options: _vm.pagination,
                "header-props": { sortIcon: null },
                items: _vm.collaborators,
                page: _vm.pagination.page,
                "server-items-length": _vm.totalCollaborators,
                "footer-props": {
                  disablePagination:
                    _vm.companiesList.length > 1 ? false : true,
                  itemsPerPageOptions:
                    _vm.totalCollaborators < 50
                      ? [
                          { value: 50, text: "50" },
                          { value: 100, text: "100", disabled: true },
                          { value: 250, text: "250", disabled: true },
                        ]
                      : _vm.totalCollaborators < 100
                      ? [
                          { value: 50, text: "50" },
                          { value: 100, text: "100" },
                          { value: 250, text: "250", disabled: true },
                        ]
                      : [
                          { value: 50, text: "50" },
                          { value: 100, text: "100" },
                          { value: 250, text: "250" },
                        ],
                  disableItemsPerPage: _vm.collaborators.length === 0,
                  itemsPerPageText: `${_vm.funcTitle} por página: `,
                  expandIcon: "mdi-dots-horizontal",
                },
                "no-data-text": _vm.noData(
                  _vm.companiesList.length === 0,
                  _vm.funcTitle
                ),
              },
              on: {
                "update:options": function ($event) {
                  _vm.pagination = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer.page-text",
                  fn: function (items) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(items.pageStart) +
                          " - " +
                          _vm._s(items.pageStop) +
                          " de " +
                          _vm._s(items.itemsLength) +
                          " " +
                          _vm._s(_vm.funcTitle) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.socialName",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.socialName) + " ")]
                  },
                },
                {
                  key: "item.name",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.start_and_end(item.name, 32).toUpperCase()
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.cnpj",
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " "),
                    ]
                  },
                },
                {
                  key: "item.cpf",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.cpf) + " ")]
                  },
                },
                {
                  key: "item.cpfMask",
                  fn: function ({ item }) {
                    return undefined
                  },
                },
                {
                  key: "item.competenceIn",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("date")(item.competenceIn, {
                              mask: "MM/YYYY",
                              showEmpty: true,
                            })
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.categoryType",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.categoryType) + " ")]
                  },
                },
                {
                  key: "item.categoryCode",
                  fn: function ({ item }) {
                    return [_vm._v(" " + _vm._s(item.categoryCode) + " ")]
                  },
                },
                {
                  key: "item.categoryInfo",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          "span",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.start_and_end(
                                                  item.categoryInfo,
                                                  65
                                                )
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v(" " + _vm._s(item.categoryInfo))])]
                      ),
                    ]
                  },
                },
                {
                  key: "item.nociveAgents",
                  fn: function ({ item }) {
                    return [
                      _vm.hasAgents(item.nociveAgents)
                        ? _c(
                            "span",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "material-symbols-outlined",
                                  attrs: { id: "iconInfo" },
                                },
                                [_vm._v(" info ")]
                              ),
                              _c("span", { attrs: { id: "iconInfoText" } }, [
                                _vm._v(" Há exposições"),
                              ]),
                            ]
                          )
                        : _c(
                            "span",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                    },
                                                  },
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "material-symbols-outlined",
                                                    attrs: { id: "iconDone" },
                                                  },
                                                  [_vm._v(" done ")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.start_and_end(
                                                          "Ausência de agente nocivo ou de atividades previstas no Anexo IV do Decreto 3.048/1999",
                                                          42
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Ausência de agente nocivo ou de atividades previstas no Anexo IV do Decreto 3.048/1999 "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  },
                },
                {
                  key: "item.specialRetirement",
                  fn: function ({ item }) {
                    return [
                      item.specialRetirement
                        ? _c("span", [_vm._v("Sim")])
                        : _c("span", [_vm._v("Não")]),
                    ]
                  },
                },
                {
                  key: "item.retirementAliquot",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.specialRetirement === false
                              ? "Ausente"
                              : item.retirementAliquot
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.lastUpdate",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dateAsTime")(item.lastUpdate)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.action",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _vm.hasAgents(item.nociveAgents)
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "btn-action material-symbols-outlined",
                                                attrs: {
                                                  id: "btn-action-" + item.id,
                                                  icon: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showModal(item)
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "material-symbols-outlined",
                                                attrs: {
                                                  id: "iconDescription",
                                                },
                                              },
                                              [_vm._v(" description ")]
                                            ),
                                          ]
                                        )
                                      : _c("span"),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Ver agentes nocivos ")])]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.loadingTable
                ? _c("template", { slot: "body" }, [
                    _c(
                      "tr",
                      _vm._l(_vm.headers, function (loadItem) {
                        return _c(
                          "td",
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "loaderCss",
                              attrs: { "max-width": loadItem.width },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
      _c("ModalForNociveAgents", {
        ref: "modalForNociveAgents",
        attrs: { colaborator: _vm.colabForModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }