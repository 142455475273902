var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "relative-container" },
    [
      _c(
        "v-menu",
        {
          staticClass: "pa-0 ma-0",
          staticStyle: {
            "max-width": "384px !important",
            "min-width": "384px !important",
            "max-height": "485px !important",
            "min-height": "485px !important",
          },
          attrs: {
            id: "idForReplay",
            attach: "",
            bottom: "",
            left: "",
            "offset-y": "",
            "close-on-content-click": false,
            "close-on-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs, value }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          class:
                            _vm.selectedLength > 0 && _vm.boolForTags
                              ? "secundary-default2"
                              : "secundary-default",
                          style: value
                            ? "background-color: #2a1c4f !important;color:white !important; box-shadow: none !important; transition: none !important;-webkit-box-shadow: none"
                            : "box-shadow:none !important; transition: none !important;-webkit-box-shadow: none;",
                          attrs: {
                            disabled: _vm.disabledOrNot,
                            ripple: false,
                            "content-class": "elevation-0",
                            icon: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.buildStyle.apply(null, arguments)
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "span",
                        { staticClass: "material-icons-outlined pr-2" },
                        [_vm._v(" filter_alt ")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedLength > 0 && _vm.boolForTags
                              ? `Filtro (${_vm.selectedLength})`
                              : "Filtro"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "mb-0 pa-0" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "pa-4",
                  staticStyle: { "max-height": "64px !important" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-h5 pa-0 ma-0",
                      style:
                        "color: #182026;font-weight:700;line-height:30px!important;font-size:20px!important;height:30px!important",
                      attrs: { id: "titleFiltro" },
                    },
                    [_vm._v("Filtro")]
                  ),
                  _c("v-spacer", { staticClass: "pa-0" }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0 ma-0",
                      staticStyle: {
                        width: "32px !important",
                        height: "32px !important",
                      },
                      attrs: {
                        id: "closeButtonFromUp",
                        icon: "",
                        dark: "",
                        ripple: false,
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closeFilter()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "material-symbols-outlined",
                          attrs: { id: "iconClose" },
                        },
                        [_vm._v(" close ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-list",
                {
                  staticClass: "ma-0 pa-0",
                  staticStyle: {
                    "max-height": "421x !important",
                    "border-top":
                      "1px solid var(--v-secondary-lighten5) !important",
                  },
                  attrs: { width: "384" },
                },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "pt-4 wws-filter-select" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          id: "vAutoCompleteAgNoc",
                          items: _vm.countNociveAgentsForFilter,
                          "append-icon": "mdi-chevron-down",
                          "item-text": "name",
                          "item-value": "code",
                          dense: "",
                          outlined: "",
                          label: "Agente nocivo",
                          clearable: "",
                          "hide-details": "",
                          placeholder: "Selecione uma opção",
                        },
                        on: { input: (value) => _vm.changeNociveAgent(value) },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "tooltip-text",
                                                    },
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.name))])]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.localFilter.nociveAgent,
                          callback: function ($$v) {
                            _vm.$set(_vm.localFilter, "nociveAgent", $$v)
                          },
                          expression: "localFilter.nociveAgent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { staticClass: "pt-4 wws-filter-select" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          id: "vAutoCompleteSpecialRetirement",
                          "append-icon": "mdi-chevron-down",
                          items: _vm.retirementRisksFilter,
                          "item-text": "name",
                          "item-value": "key",
                          dense: "",
                          outlined: "",
                          label: "Aposentadoria especial",
                          clearable: "",
                          "hide-details": "",
                          placeholder: "Selecione uma opção",
                        },
                        on: {
                          input: (value) => _vm.changeSpecialRetirement(value),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index, item }) {
                              return [
                                index === 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "primary--text",
                                        staticStyle: { "max-height": "48px" },
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.localFilter.specialRetirement,
                          callback: function ($$v) {
                            _vm.$set(_vm.localFilter, "specialRetirement", $$v)
                          },
                          expression: "localFilter.specialRetirement",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { staticClass: "pt-4 wws-filter-select" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          id: "vAutoCompleteAliqContri",
                          items: _vm.aliqContributionOptions,
                          "item-text": "text",
                          dense: "",
                          outlined: "",
                          "return-object": "",
                          "append-icon": "mdi-chevron-down",
                          label: "Contribuição / Alíquota",
                          clearable: "",
                          "hide-details": "",
                          placeholder: "Selecione uma opção",
                        },
                        on: {
                          input: (value) => _vm.changeRetirementAliquot(value),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index, item }) {
                              return [
                                index === 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "primary--text",
                                        staticStyle: { "max-height": "48px" },
                                      },
                                      [_vm._v(" " + _vm._s(item.text) + " ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.localFilter.retirementAliquot,
                          callback: function ($$v) {
                            _vm.$set(_vm.localFilter, "retirementAliquot", $$v)
                          },
                          expression: "localFilter.retirementAliquot",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { staticClass: "pt-4" },
                    [
                      _c("v-switch", {
                        staticClass: "pa-0 ma-0 ml-1 font-weight-regular",
                        attrs: {
                          ripple: false,
                          id: "switchId",
                          "hide-details": "",
                          inset: "",
                          label: "Risco apresentado",
                        },
                        on: {
                          change: (value) => _vm.changeOnlyExposedRisk(value),
                        },
                        model: {
                          value: _vm.localFilter.onlyExposedRisks,
                          callback: function ($$v) {
                            _vm.$set(_vm.localFilter, "onlyExposedRisks", $$v)
                          },
                          expression: "localFilter.onlyExposedRisks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { staticClass: "pa-4 pt-8" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "buttonApplyStyle",
                          style: _vm.habilitarBotaoFiltro
                            ? "width: 352px; max-height: 48px !important; min-height: 48px !important;"
                            : "width: 352px; max-height: 48px !important; min-height: 48px !important;",
                          attrs: {
                            id: "buttonApply",
                            disabled: _vm.boolForTags,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.applyFilter()
                            },
                          },
                        },
                        [_vm._v(" Aplicar filtro ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { staticClass: "pb-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "buttonCleanStyle primary--text",
                          style: _vm.habilitarBotaoLimparFiltro
                            ? "width: 352px; max-height: 48px !important; min-height: 48px !important;"
                            : "width: 352px; max-height: 48px !important; min-height: 48px !important;",
                          attrs: {
                            id: "buttonClear",
                            disabled: _vm.selectedLength < 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cleanFilter()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "material-symbols-outlined mr-2" },
                            [_vm._v(" delete ")]
                          ),
                          _vm._v(" Limpar filtro"),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }